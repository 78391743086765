
@charset "utf-8";

// COLORS
// $theme-colors: (
//   "primary": #0074d9,
//   "secondary": #6c757d,
//   "success": #28a745,
//   "danger": #ff4136,
//   "warning": #ffc107,
//   "info": #17a2b8,
//   "light": #f8f9fa,
//   "dark": #343a40,
//   "white": #ffffff,
//   "transparent": transparent,
// );

// TEXT
// $font-family-sans-serif: "Helvetica Neue", Helvetica, Arial, sans-serif;
// $font-family-serif: Georgia, "Times New Roman", Times, serif;
// $font-family-base: $font-family-sans-serif;
// $font-size-base: 1rem;

// BREAKPOINTS
// $grid-breakpoints: (
//   xs: 0,
//   sm: 576px,
//   md: 768px,
//   lg: 992px,
//   xl: 1200px,
// );

// SEE DOCS FOR MORE:
// https://bit.ly/2sgFMdb

// IMPORT BOOTSTRAP
@import "~bootstrap/scss/bootstrap";

// IMPORT FONT AWESOME
@import url("https://use.fontawesome.com/releases/v5.10.1/css/all.css");

// #root {
//   // Full height of screen and flex so that we can push footer
//   // to the bottom even if content is shorter than screen
//   min-height: 100vh;
//   display: flex;
//   flex-direction: column;
//   // Push footer to the bottom of the screen
//   > .footer {
//     margin-top: auto;
//   }
//   // Prevent child elements from shrinking when content
//   // is taller than the screen (quirk of having flex parent)
//   > * {
//     flex-shrink: 0;
//   }
// }
@import "./css/animate.css";

@import "./css/muzex-icons.css";
@import url('https://fonts.googleapis.com/css2?family=Karla:wght@200;300;400;500&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Karla:wght@200;300;400;500&family=Playfair+Display:wght@400;500;600;700;800;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Karla:wght@200;300;400;500&family=Playfair+Display:wght@400;500;600;700;800;900&family=Work+Sans:wght@100;200;300;400;500;600;700;800;900&display=swap');

:root {
	--thm-font: "Karla", sans-serif;
	--heading-font: "Playfair Display", serif;
	--special-font: "Work Sans", sans-serif;
  // --thm-base: #aaa081;
  --thm-base: #f891A5;
	--thm-base-rgb: 170, 160, 129;
	--thm-black: #252930;
	--thm-black-rgb: 37, 41, 48;
}

body {
	font-family: var(--thm-font);
	font-size: 16px;
	line-height: 28px;
  color: #848484;
  overflow-x: hidden;
}

[dir=rtl] .thm__owl-carousel {
	direction: ltr;
}


h1,
h2,
h3,
h4,
h5,
h6 {
	font-family: var(--heading-font);
}

a,
a:hover,
a:active,
a:focus {
	text-decoration: none;
}

.page-wrapper {
	position: relative;
	margin: 0 auto;
	width: 100%;
	min-width: 300px;
	overflow: hidden;
}

@media (min-width: 1200px) {
	.container {
		max-width: 1200px;
	}
}

.thm-btn {
	display: inline-block;
	vertical-align: middle;
	border: none;
	outline: none;
	color: #ffffff;
	font-size: 17px;
	font-weight: 600;
	background-color: var(--thm-base);
	padding: 13.5px 36.5px;
	text-align: center;
	-webkit-transition: all 500ms ease;
	transition: all 500ms ease;
}

.thm-btn:hover {
	background-color: #fff;
	color: var(--thm-black);
}

/* Cursor*/

.cursor {
	position: absolute;
	background-color: #fff;
	width: 6px;
	height: 6px;
	border-radius: 100%;
	z-index: 1;
	-webkit-transition: 0.2s cubic-bezier(0.75, -0.27, 0.3, 1.33) opacity,
		0.3s cubic-bezier(0.75, -1.27, 0.3, 2.33) -webkit-transform;
	transition: 0.2s cubic-bezier(0.75, -0.27, 0.3, 1.33) opacity,
		0.3s cubic-bezier(0.75, -1.27, 0.3, 2.33) -webkit-transform;
	transition: 0.3s cubic-bezier(0.75, -1.27, 0.3, 2.33) transform,
		0.2s cubic-bezier(0.75, -0.27, 0.3, 1.33) opacity;
	transition: 0.3s cubic-bezier(0.75, -1.27, 0.3, 2.33) transform,
		0.2s cubic-bezier(0.75, -0.27, 0.3, 1.33) opacity,
		0.3s cubic-bezier(0.75, -1.27, 0.3, 2.33) -webkit-transform;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
	pointer-events: none;
	z-index: 10000;
	-webkit-transform: scale(1);
	transform: scale(1);
	visibility: hidden;
}

.cursor {
	visibility: visible;
}

.cursor.active {
	opacity: 0.5;
	-webkit-transform: scale(0);
	transform: scale(0);
}

.cursor.hovered {
	opacity: 0.08;
}

.cursor-follower {
	position: absolute;
	background-color: RGBA(255, 255, 255, 0.3);
	width: 50px;
	height: 50px;
	border-radius: 100%;
	z-index: 1;
	-webkit-transition: 0.2s cubic-bezier(0.75, -0.27, 0.3, 1.33) opacity,
		0.6s cubic-bezier(0.75, -1.27, 0.3, 2.33) -webkit-transform;
	transition: 0.2s cubic-bezier(0.75, -0.27, 0.3, 1.33) opacity,
		0.6s cubic-bezier(0.75, -1.27, 0.3, 2.33) -webkit-transform;
	transition: 0.6s cubic-bezier(0.75, -1.27, 0.3, 2.33) transform,
		0.2s cubic-bezier(0.75, -0.27, 0.3, 1.33) opacity;
	transition: 0.6s cubic-bezier(0.75, -1.27, 0.3, 2.33) transform,
		0.2s cubic-bezier(0.75, -0.27, 0.3, 1.33) opacity,
		0.6s cubic-bezier(0.75, -1.27, 0.3, 2.33) -webkit-transform;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
	pointer-events: none;
	z-index: 10000;
	visibility: hidden;
}

.cursor-follower {
	visibility: visible;
}

.cursor-follower.active {
	opacity: 0.7;
	-webkit-transform: scale(1);
	transform: scale(1);
}

.cursor-follower.hovered {
	opacity: 0.08;
}

.cursor-follower.close-cursor:before {
	position: absolute;
	content: "";
	height: 25px;
	width: 2px;
	background: #fff;
	left: 48%;
	top: 12px;
	-webkit-transform: rotate(-45deg);
	transform: rotate(-45deg);
	display: inline-block;
}

.cursor-follower.close-cursor:after {
	position: absolute;
	content: "";
	height: 25px;
	width: 2px;
	background: #fff;
	right: 48%;
	top: 12px;
	-webkit-transform: rotate(45deg);
	transform: rotate(45deg);
}

/* bootstrap select */
.bootstrap-select .btn-light:not(:disabled):not(.disabled).active,
.bootstrap-select .btn-light:not(:disabled):not(.disabled):active,
.bootstrap-select .show>.btn-light.dropdown-toggle {
	background-color: inherit !important;
	box-shadow: none !important;
	outline: none !important;
}

.bootstrap-select>.dropdown-toggle {
	box-shadow: none !important;
}

.bootstrap-select .dropdown-toggle:focus,
.bootstrap-select>select.mobile-device:focus+.dropdown-toggle {
	outline: none !important;
}

.bootstrap-select .dropdown-menu {
	padding-top: 0;
	padding-bottom: 0;
	margin-top: 0;
	z-index: 991;
}

.bootstrap-select .dropdown-menu>li+li>a {
	border-top: 1px solid #f4f4f4;
}

.bootstrap-select .dropdown-menu>li:first-child>a {
	padding-top: 10px;
}

.bootstrap-select .dropdown-menu>li:last-child>a {
	padding-bottom: 10px;
}

.bootstrap-select .dropdown-menu>li.selected>a {
	background: var(--thm-black);
	color: #fff;
}

.bootstrap-select .dropdown-menu>li>a {
	font-size: 16px;
	font-weight: 500;
	padding: 9px 20px;
	color: var(--thm-black);
	-webkit-transition: all 0.4s ease;
	transition: all 0.4s ease;
}

.bootstrap-select .dropdown-menu>li>a:hover {
	background: var(--thm-black);
	color: #fff;
	cursor: pointer;
}

.scroll-to-top {
	display: inline-block;
	width: 50px;
	height: 50px;
	background: var(--thm-black);
	border-radius: 50%;
	position: fixed;
	bottom: 40px;
	right: 40px;
	z-index: 990;
	text-align: center;
	-webkit-transition: all 0.4s ease;
	transition: all 0.4s ease;
	display: none;
	border: 1px solid var(--thm-black);
}

@media (max-width: 480px) {
	.scroll-to-top {
		width: 40px;
		height: 40px;
	}
}

.scroll-to-top i {
	font-size: 14px;
	line-height: 50px;
	color: #fff;
	position: relative;
	z-index: 10;
	-webkit-transition: all 0.4s ease;
	transition: all 0.4s ease;
}

@media (max-width: 480px) {
	.scroll-to-top i {
		font-size: 14px;
		line-height: 40px;
	}
}

.scroll-to-top:hover {
	background-color: #fff;
}

.scroll-to-top:hover i {
	color: var(--thm-black);
}

.post-pagination {
	text-align: center;
	display: -webkit-box;
	display: flex;
	-webkit-box-pack: center;
	justify-content: center;
	-webkit-box-align: center;
	align-items: center;
}

.post-pagination a {
	color: var(--thm-black);
	font-size: 18px;
	font-weight: 700;
	border-style: solid;
	border-width: 1px;
	border-color: #e3e9ec;
	padding-left: 18.5px;
	padding-right: 18.5px;
	padding-top: 8px;
	padding-bottom: 8px;
	-webkit-transition: all 0.4s ease;
	transition: all 0.4s ease;
}

.post-pagination a.active,
.post-pagination a:hover {
	color: #fff;
	background-color: var(--thm-black);
	border-color: var(--thm-black);
}

.post-pagination a+a {
	margin-left: 10px;
}

.post-pagination a.active {
	cursor: auto;
}

.post-pagination__prev,
.post-pagination__next {
	border: none !important;
	background-color: transparent !important;
	text-decoration: underline;
	color: var(--thm-black) !important;
	text-transform: uppercase;
	font-size: 14px !important;
}

/* datepicker */
.datepicker-dropdown {
	min-width: 300px;
}

.datepicker-dropdown table {
	width: 100%;
}

.datepicker table tr td span.active.active,
.datepicker table tr td span.active.disabled.active,
.datepicker table tr td span.active.disabled:active,
.datepicker table tr td span.active.disabled:hover.active,
.datepicker table tr td span.active.disabled:hover:active,
.datepicker table tr td span.active:active,
.datepicker table tr td span.active:hover.active,
.datepicker table tr td span.active:hover:active {
	background-color: var(--thm-black);
	background-image: none;
}

.datepicker table tr td.active.active,
.datepicker table tr td.active.disabled.active,
.datepicker table tr td.active.disabled:active,
.datepicker table tr td.active.disabled:hover.active,
.datepicker table tr td.active.disabled:hover:active,
.datepicker table tr td.active:active,
.datepicker table tr td.active:hover.active,
.datepicker table tr td.active:hover:active,
.datepicker table tr td.active,
.datepicker table tr td.active.disabled,
.datepicker table tr td.active.disabled:hover,
.datepicker table tr td.active:hover {
	background-color: var(--thm-base);
	background-image: none;
}

/* preloader */

.preloader {
	position: fixed;
	width: 100%;
	height: 100%;
	background-color: #fff;
	top: 0;
	left: 0;
	z-index: 99999999;
	display: -webkit-box;
	display: flex;
	-webkit-box-align: center;
	        align-items: center;
	-webkit-box-pack: center;
	        justify-content: center;
}

.lds-ripple {
	display: inline-block;
	position: relative;
	width: 80px;
	height: 80px;
}

.lds-ripple div {
	position: absolute;
	border: 4px solid var(--thm-black);
	opacity: 1;
	border-radius: 50%;
	-webkit-animation: lds-ripple 1s cubic-bezier(0, 0.2, 0.8, 1) infinite;
	        animation: lds-ripple 1s cubic-bezier(0, 0.2, 0.8, 1) infinite;
}

.lds-ripple div:nth-child(2) {
	-webkit-animation-delay: -0.5s;
	        animation-delay: -0.5s;
}

@-webkit-keyframes lds-ripple {
	0% {
		top: 36px;
		left: 36px;
		width: 0;
		height: 0;
		opacity: 1;
	}

	100% {
		top: 0px;
		left: 0px;
		width: 72px;
		height: 72px;
		opacity: 0;
	}
}

@keyframes lds-ripple {
	0% {
		top: 36px;
		left: 36px;
		width: 0;
		height: 0;
		opacity: 1;
	}

	100% {
		top: 0px;
		left: 0px;
		width: 72px;
		height: 72px;
		opacity: 0;
	}
}

/* Block Title */

.block-title p {
	margin: 0;
	font-size: 17px;
	line-height: 1em;
	font-weight: bold;
	color: var(--thm-base);
	text-transform: uppercase;
	letter-spacing: 0.15em;
	display: -webkit-box;
	display: flex;
	-webkit-box-align: center;
	        align-items: center;
}

.block-title p::before {
	content: "";
	width: 60px;
	height: 2px;
	background-color: var(--thm-base);
	display: block;
	-webkit-transform: translateX(-50%);
	        transform: translateX(-50%);
}

.block-title h3 {
	font-family: "Playfair Display";
	font-weight: bold;
	color: var(--thm-black);
	font-size: 44px;
	margin: 0;
	margin-top: 25px;
}

.block-title-two {
	margin-bottom: 60px;
	position: relative;
	padding-top: 170px;
}

.block-title-two__line {
	width: 2px;
	height: 110px;
	background-color: var(--thm-base);
	position: absolute;
	top: -55px;
	left: calc(50% - 1px);
}

.block-title-two p {
	margin: 0;
	font-size: 17px;
	line-height: 1em;
	font-weight: bold;
	color: var(--thm-base);
	text-transform: uppercase;
	letter-spacing: 0.15em;
	margin-bottom: 30px;
}

.block-title-two h3 {
	font-family: "Playfair Display";
	font-weight: bold;
	color: var(--thm-black);
	font-size: 44px;
	margin: 0;
}

/* More Post link */

.more-post__link {
	width: 100px;
	height: 100px;
	display: block;
	position: relative;
	display: -webkit-box;
	display: flex;
	-webkit-box-align: center;
	        align-items: center;
	-webkit-box-pack: center;
	        justify-content: center;
	text-align: center;
	font-size: 14px;
	color: #252930;
	flex-wrap: wrap;
	line-height: 1.2em;
	padding: 0 22px;
	text-transform: uppercase;
}

.more-post__link:hover {
	color: var(--thm-black);
}

.more-post__link .curved-circle {
	position: absolute;
	top: 50%;
	left: 50%;
	// -webkit-transform: translate(-50%, -50%) rotate(0);
	//         transform: translate(-50%, -50%) rotate(0);
	-webkit-transform: translate(-60%, -50%) rotate(0);
	        transform: translate(-60%, -50%) rotate(0);
	width: 100px;
	font-size: 8px;
	color: var(--thm-base);
	text-transform: uppercase;
	-webkit-transition: 500ms;
	transition: 500ms;
}

.more-post__link:hover .curved-circle {
	// -webkit-transform: translate(-50%, -50%) rotate(180deg);
	//         transform: translate(-50%, -50%) rotate(180deg);
	-webkit-transform: translate(-40%, -50%) rotate(180deg);
	        transform: translate(-40%, -50%) rotate(180deg);
}

/*-------------------------------------------------------------- 
# Animations
--------------------------------------------------------------*/

@-webkit-keyframes ImgBounce {
	0% {
		-webkit-transform: translateY(0);
		transform: translateY(0);
	}

	100% {
		-webkit-transform: translateY(-30px);
		transform: translateY(-30px);
	}
}

@keyframes ImgBounce {
	0% {
		-webkit-transform: translateY(0);
		transform: translateY(0);
	}

	100% {
		-webkit-transform: translateY(-30px);
		transform: translateY(-30px);
	}
}

@-webkit-keyframes slideBgImage {
	from {
		-webkit-transform: scale(1);
		        transform: scale(1);
	}

	to {
		-webkit-transform: scale(1.15);
		        transform: scale(1.15);
	}
}

@keyframes slideBgImage {
	from {
		-webkit-transform: scale(1);
		        transform: scale(1);
	}

	to {
		-webkit-transform: scale(1.15);
		        transform: scale(1.15);
	}
}

/*-------------------------------------------------------------- 
# Topbar
--------------------------------------------------------------*/

.topbar-one {
	border-bottom: 1px solid #e5e5e5;
	padding-top: 16px;
	padding-bottom: 16px;
}

.topbar-one .container {
	display: -webkit-box;
	display: flex;
	-webkit-box-align: center;
	        align-items: center;
	-webkit-box-pack: justify;
	        justify-content: space-between;
}

.topbar-one p {
	font-size: 16px;
	color: #848484;
	margin: 0;
}

.topbar-one__right {
	display: -webkit-box;
	display: flex;
	-webkit-box-align: center;
	        align-items: center;
}

.topbar-one__right a {
	display: -webkit-box;
	display: flex;
	-webkit-box-align: center;
	        align-items: center;
	font-size: 16px;
	color: #848484;
}

.topbar-one__right a i {
	margin-right: 10px;
}

.topbar-one__right a+a {
	margin-left: 30px;
}

.topbar-one__right .topbar__btn {
	background-color: var(--thm-black);
	font-size: 16px;
	color: #fff;
	padding: 7px 19.65px;
	-webkit-transition: all 500ms ease;
	transition: all 500ms ease;
	border: 1px solid var(--thm-black);
}

.topbar__btn:hover {
	background-color: #fff;
	color: var(--thm-black);
}

.topbar-one__home-two {
	background-color: var(--thm-black);
	border-bottom: 0;
}

.topbar-one__home-two .topbar__btn {
	background-color: var(--thm-base);
}

.topbar-one__home-two .topbar__btn:hover {
	background-color: #fff;
	color: var(--thm-black);
}

.topbar-one__home-two .topbar-one__right a:not(.topbar__btn) {
	color: #ffffff;
	opacity: 40%;
	-webkit-transition: all 500ms ease;
	transition: all 500ms ease;
}

.topbar-one__home-two .topbar-one__right a:not(.topbar__btn):hover {
	opacity: 100%;
}

.topbar-one__home-two .topbar-one p {
	color: #ffffff;
	opacity: 40%;
}

/* shadowed carousel */

.shadowed__carousel .owl-stage-outer {
	overflow: visible;
}

.shadowed__carousel .owl-item {
	opacity: 0;
	visibility: hidden;
	-webkit-transition: opacity 500ms ease, visibility 500ms ease;
	transition: opacity 500ms ease, visibility 500ms ease;
}

.shadowed__carousel .owl-item.active {
	opacity: 1;
	visibility: visible;
}


/*-------------------------------------------------------------- 
# Main Menu
--------------------------------------------------------------*/

.main-nav-one .inner-container {
	display: -webkit-box;
	display: flex;
	-webkit-box-align: center;
          align-items: center;
          width:100%;
}

.main-nav-one .side-menu__toggler {
	// display: none;
}

.main-nav-one .logo-box {
	border-right: 1px solid #e5e5e5;
  // padding: 38.25px 0;
  padding: 0 0;
	padding-right: 75px;
}

.main-nav-one .main-nav__main-navigation {
	margin-left: auto;
}

.main-nav-one .main-nav__main-navigation ul {
	margin: 0;
	padding: 0;
	list-style: none;
}

.main-nav-one .dropdown-btn {
	display: none;
}

.main-nav-one .main-nav__main-navigation .main-nav__navigation-box {
	display: -webkit-box;
	display: flex;
	-webkit-box-align: center;
	        align-items: center;
}

.main-nav-one .main-nav__navigation-box>li {
  // padding: 45.75px 0;
  padding: 5px 0;
  // padding: 5px 10px;
}
.main-nav-one .main-nav__navigation-box>li:hover {
  background: #f891a526;
}
.main-nav-one .main-nav__navigation-box>li+li {
	margin-left: 20px;
}

.main-nav-one .main-nav__navigation-box>li>a {
	font-size: 18px;
	color: var(--thm-black);
	display: -webkit-box;
	display: flex;
	-webkit-box-align: center;
          align-items: center;
          padding: 0px 15px;
}
.main-nav-one .main-nav__navigation-box>li.active {
    background: #f891a526;

}

.main-nav-one .main-nav__navigation-box .dropdown>a::after {
	content: "\f107";
	font-family: "Font Awesome 5 Free";
	font-weight: 900;
	margin-left: 10px;
}

/* Dropdown menu */

.main-nav__main-navigation .dropdown-btn {
	display: none;
}

.main-nav__main-navigation .main-nav__navigation-box>li ul {
	position: absolute;
	width: 210px;
	background-color: #fff;
	border-top: 2px solid var(--thm-black);
	box-shadow: 2px 2px 5px 1px rgba(0, 0, 0, 0.05),
		-2px 0px 5px 1px rgba(0, 0, 0, 0.05);
	-webkit-transform-origin: top;
	transform-origin: top;
	visibility: hidden;
	opacity: 0;
	z-index: 991;
	-webkit-transition: opacity 500ms ease, visibility 500ms ease,
		-webkit-transform 500ms ease;
	transition: opacity 500ms ease, visibility 500ms ease,
		-webkit-transform 500ms ease;
	-webkit-transition: opacity 500ms ease, visibility 500ms ease, -webkit-transform 500ms ease;
	transition: opacity 500ms ease, visibility 500ms ease, -webkit-transform 500ms ease;
	transition: transform 500ms ease, opacity 500ms ease, visibility 500ms ease;
	transition: transform 500ms ease, opacity 500ms ease, visibility 500ms ease, -webkit-transform 500ms ease;
	transition: transform 500ms ease, opacity 500ms ease, visibility 500ms ease,
		-webkit-transform 500ms ease;
}

.main-nav__main-navigation .main-nav__navigation-box>li ul>li {
	position: relative;
}

.main-nav__main-navigation .main-nav__navigation-box>li ul .dropdown>a {
	position: relative;
}

.main-nav__main-navigation .main-nav__navigation-box>li ul .dropdown>a::after {
	position: absolute;
	right: 25px;
	top: 50%;
	-webkit-transform: translateY(-50%) rotate(-90deg);
	        transform: translateY(-50%) rotate(-90deg);
}

.main-nav__main-navigation .main-nav__navigation-box>li ul>li+li {
	border-top: 1px solid rgba(var(--thm-black-rgb), 0.15);
}

.main-nav__main-navigation .main-nav__navigation-box>li ul>li>a {
	display: block;
	color: var(--thm-black);
	font-size: 16px;
	font-family: var(--thm-font);
	word-break: break-all;
	padding-top: 9.5px;
	padding-bottom: 9.5px;
	padding-left: 20px;
	padding-right: 20px;
	-webkit-transition: all 500ms ease;
	transition: all 500ms ease;
}

.main-nav__main-navigation .main-nav__navigation-box>li ul>li:hover>a {
	color: #fff;
	background-color: var(--thm-black);
}

/* Second level menu */

.main-nav__main-navigation .main-nav__navigation-box>li>ul {
	top: 100%;
	left: 0;
	-webkit-transform: perspective(300px) scaleY(0) translateZ(30px);
	        transform: perspective(300px) scaleY(0) translateZ(30px);

	visibility: hidden;
	opacity: 0;
}

.main-nav__main-navigation .main-nav__navigation-box>li:hover>ul {
	-webkit-transform: perspective(300px) scaleY(1) translateZ(0);
	        transform: perspective(300px) scaleY(1) translateZ(0);
	visibility: visible;
	opacity: 1;
}

/* Third level menu */

.main-nav__main-navigation .main-nav__navigation-box>li>ul>li>ul {
	top: 0;
	left: 100%;
	-webkit-transform: perspective(300px) scaleX(0);
	        transform: perspective(300px) scaleX(0);
	visibility: hidden;
	opacity: 0;
	-webkit-transform-origin: left;
	        transform-origin: left;
}

.main-nav__main-navigation .main-nav__navigation-box>li>ul>li:hover>ul {
	-webkit-transform: perspective(300px) scaleX(1);
	        transform: perspective(300px) scaleX(1);
	visibility: visible;
	opacity: 1;
}

/* After Third level menu */

.main-nav__main-navigation .main-nav__navigation-box>li>ul>li>ul>li>ul {
	display: none;
}

/* Right Side */

.main-nav-one .main-nav__right {
	display: -webkit-box;
	display: flex;
	-webkit-box-align: center;
	        align-items: center;
	// border-left: 1px solid #e5e5e5;
  // padding: 46px 0;
  padding: 20px 0;
	padding-left: 50px;
	// margin-left: 50px;
}

// .main-nav-one .main-nav__right a {
// 	font-size: 20px;
// 	color: var(--thm-black);
// 	-webkit-transition: all 500ms ease;
// 	transition: all 500ms ease;
// }

.main-nav-one .main-nav__right a+a {
	margin-left: 30px;
}

/* stricked menu */
.stricked-menu {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	z-index: 999;
	background-color: #ffffff;
	box-shadow: 0px 6px 13px 0px rgba(0, 0, 0, 0.06);
	opacity: 0;
	-webkit-transform: translateY(-110%);
	transform: translateY(-110%);
	-webkit-transition: all 0.4s ease;
	transition: all 0.4s ease;
}

.stricked-menu.stricky-fixed {
	opacity: 1;
	-webkit-transform: translateY(0%);
	transform: translateY(0%);
}

.stricked-menu .logo-box {
	padding-top: 14.25px;
	padding-bottom: 14.25px;
}

.stricked-menu .main-nav__main-navigation .main-nav__navigation-box>li {
	padding: 22px 0;
}

.stricked-menu .main-nav__right {
	padding-top: 22px;
	padding-bottom: 22px;
}

/* home two */

.main-nav-one__home-two .logo-box {
	padding-right: 0;
	border-right: 0;
}

.main-nav-one__home-two .main-nav__right {
	border: none;
	padding-left: 40px;
	margin-left: 0;
}

/* home three */
.main-nav-one__home-three {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	z-index: 91;
	border-bottom: 1px solid rgba(255, 255, 255, .2);
}

.main-nav-one__home-three .logo-box {
	border-right: 0;
	padding-right: 0;
}

.main-nav-one__home-three .main-nav__right a,
.main-nav-one__home-three .main-nav__navigation-box>li>a {
	color: #ffffff;
}

.main-nav-one__home-three .main-nav__right {
	padding-left: 0;
	border-left: 0;
	margin-left: 40px;
}

/* home three stricked */
.main-nav-one__home-three.stricked-menu {
	position: fixed;
	background-color: var(--thm-black);
	border-bottom: 0;
}

/*-------------------------------------------------------------- 
# Banner
--------------------------------------------------------------*/

.banner-section {
	position: relative;
}

.banner-carousel {
	position: relative;
}

.banner-carousel .slide-item {
	position: relative;
	display: block;
	background-color: #000000;
	background-repeat: no-repeat;
	background-position: center;
	background-size: cover;
	padding: 120px 0px 220px;
	overflow: hidden;
}

.style-two .banner-carousel .slide-item {
	padding: 340px 0px 210px;
}

.style-three .banner-carousel .slide-item {
	padding: 300px 0px 270px;
}

.banner-carousel .slide-item .curved-layer {
	position: absolute;
	left: 0;
	top: 0;
	width: 45%;
	height: 100%;
}

.banner-carousel .slide-item .curved-layer:before {
	content: "";
	position: absolute;
	right: 0;
	top: -80%;
	width: 150%;
	height: 200%;
	opacity: 0.85;
	-webkit-transform-origin: left;
	transform-origin: left;
	-webkit-transform: rotate(20deg) scaleX(0);
	transform: rotate(20deg) scaleX(0);
}

.banner-carousel .active .slide-item .curved-layer:before {
	-webkit-transform: rotate(20deg) scaleX(1);
	transform: rotate(20deg) scaleX(1);
	-webkit-transition: all 700ms linear;
	transition: all 700ms linear;
}

.banner-carousel .slide-item .image-layer {
	position: absolute;
	left: 0;
	top: 0;
	width: 100%;
	height: 100%;
	opacity: 1;
	background-repeat: no-repeat;
	background-position: center;
	background-size: cover;
	-webkit-transform: scale(1);
	transform: scale(1);
}

.banner-carousel .active .slide-item .image-layer {
	-webkit-animation-name: slideBgImage;
	        animation-name: slideBgImage;
	-webkit-animation-duration: 7000ms;
	        animation-duration: 7000ms;
	-webkit-animation-iteration-count: infinite;
	        animation-iteration-count: infinite;
	-webkit-animation-timing-function: linear;
	        animation-timing-function: linear;
}

.banner-carousel .slide-item:before {
	position: absolute;
	left: 0;
	top: 0;
	height: 100%;
	width: 100%;
	background-color: #000000;
	opacity: 0;
	content: "";
	z-index: 1;
}

.banner-carousel .content-box {
	position: relative;
	margin: 0 auto;
	padding: 0 0px;
	z-index: 5;
}

.banner-carousel .content-box:before {
	position: absolute;
	content: "";
	left: -220px;
	top: -120px;
	width: 650px;
	height: 650px;
	border-radius: 50%;
}

.style-three .banner-carousel .content-box {
	text-align: left;
	max-width: 1000px;
	margin: 0 0;
}

.banner-carousel .content-box h3 {
	font-family: var(--thm-font);
	display: block;
	font-size: 24px;
	line-height: 1.15em;
	color: #ffffff;
	font-weight: 700;
	opacity: 1;
	margin-bottom: 35px;
	letter-spacing: 0;
	-webkit-transform: translateY(-100px);
	transform: translateY(-100px);
	text-transform: capitalize;
}

.banner-carousel .active .content-box h3 {
	opacity: 1;
	-webkit-transform: translateY(0);
	transform: translateY(0);
	-webkit-transition: all 1000ms ease;
	transition: all 1000ms ease;
	-webkit-transition-delay: 700ms;
	transition-delay: 700ms;
}

.banner-carousel .content-box h2 {
	display: block;
	font-size: 45px;
	line-height: 1.15em;
	color: #ffffff;
	font-weight: 700;
	opacity: 1;
	margin-bottom: 30px;
	position: relative;
	// -webkit-transform: translateY(-100px);
	// transform: translateY(-100px);
	text-transform: capitalize;
}

.banner-carousel .content-box h2 span {
	font-weight: 300;
}

.banner-carousel .active .content-box h2 {
	opacity: 1;
	-webkit-transform: translateY(0);
	transform: translateY(0);
	-webkit-transition: all 900ms ease;
	transition: all 900ms ease;
	-webkit-transition-delay: 300ms;
	transition-delay: 300ms;
}

.banner-carousel .content-box .text {
	display: block;
	font-size: 20px;
	line-height: 1.6em;
	color: #ffffff;
	font-weight: 400;
	opacity: 1;
	margin: 0 auto;
	margin-bottom: 35px;
	-webkit-transform: translateY(80px);
	transform: translateY(80px);
}

.style-three .banner-carousel .content-box .text {
	text-align: left;
	max-width: 600px;
	margin: 0 0 40px;
}

.banner-carousel .active .content-box .text {
	opacity: 1;
	-webkit-transform: translateY(0);
	transform: translateY(0);
	-webkit-transition: all 700ms ease;
	transition: all 700ms ease;
	-webkit-transition-delay: 700ms;
	transition-delay: 700ms;
}

.banner-carousel .content-box .btn-box {
	position: relative;
	opacity: 1;
	z-index: 5;
	// -webkit-transform: translateY(80px);
	// transform: translateY(80px);
	margin-top: 70px;
}

.banner-carousel .active .content-box .btn-box {
	opacity: 1;
	-webkit-transform: translateY(0);
	transform: translateY(0);
	-webkit-transition: all 700ms ease;
	transition: all 700ms ease;
	-webkit-transition-delay: 1400ms;
	transition-delay: 1400ms;
}

.banner-carousel .content-box .btn-box .thm-btn {
	margin-right: 20px;
	padding: 13.5px 40.5px;
  // background-color: #fff;
  background-color: var(--thm-base);
  // color: var(--thm-black);
  color: #fff;
}

.banner-carousel .content-box .btn-box .thm-btn:hover {
	background-color: var(--thm-black);
	color: #fff;
}

.banner-carousel .content-box .btn-box .thm-btn:last-child {
	margin: 0;
}

.banner-section .owl-theme .owl-nav {
	position: absolute;
	top: 50%;
	left: 0;
	right: 0;
	margin: 0 auto;
	width: 100%;
}

.banner-section .owl-theme .owl-nav .owl-prev {
	position: absolute;
	left: 50px;
	top: 0;
	background: rgba(255, 255, 255, 0.15);
	height: 56px;
	width: 56px;
	border-radius: 50%;
	border: none;
	text-align: center;
	color: rgba(0, 0, 0, 0);
	line-height: 56px;
	font-size: 0px;
	opacity: 1;
	margin-top: -30px;
	-webkit-transition: all 300ms ease;
	transition: all 300ms ease;
}

.banner-section .owl-theme .owl-nav .owl-prev:after {
	font-family: "Font Awesome 5 Free";
	content: "\f104";
	font-weight: 900;
	position: absolute;
	top: 0;
	width: 56px;
	height: 56px;
	line-height: 56px;
	left: 0;
	color: #ffffff;
	font-size: 24px;
	-webkit-transition: all 300ms ease;
	transition: all 300ms ease;
}

.banner-section .owl-theme .owl-nav .owl-prev:hover:after {
	color: #222;
}

.banner-section .owl-theme .owl-nav .owl-next {
	position: absolute;
	right: 50px;
	top: 0;
	background: rgba(255, 255, 255, 0.15);
	height: 56px;
	width: 56px;
	border-radius: 50%;
	border: none;
	text-align: center;
	line-height: 56px;
	color: rgba(0, 0, 0, 0);
	font-size: 0px;
	opacity: 1;
	margin-top: -30px;
	-webkit-transition: all 300ms ease;
	transition: all 300ms ease;
}

.banner-section .owl-theme .owl-nav .owl-next:after {
	font-family: "Font Awesome 5 Free";
	font-weight: 900;
	content: "\f105";
	position: absolute;
	top: 0;
	width: 56px;
	height: 56px;
	line-height: 56px;
	right: 0;
	color: #ffffff;
	font-size: 24px;
	-webkit-transition: all 300ms ease;
	transition: all 300ms ease;
}

.banner-section .owl-theme .owl-nav .owl-next:hover:after {
	color: #222;
}

.banner-section .owl-theme .owl-nav .owl-prev:hover,
.banner-section .owl-theme .owl-nav .owl-next:hover {
	opacity: 1;
	background-color: #fff;
}

.banner-section .owl-dots {
	position: absolute;
	left: 0;
	margin: 0;
	bottom: 40px;
	width: 100%;
	text-align: center;
	display: none;
}

.banner-section .owl-dots .owl-dot {
	position: relative;
	display: inline-block;
	width: 12px;
	height: 12px;
	margin: 0px 4px;
}

.banner-section .owl-dots .owl-dot span {
	position: relative;
	display: block;
	width: 12px;
	height: 12px;
	background: #ffffff;
	border-radius: 50%;
}

.banner-section .btn-style-one .btn-title {
	background-color: #fff;
	color: #222;
}

.banner-section .btn-style-one:hover .btn-title {
	color: #fff;
}

.banner-section__home-two .content-box h3 {
	color: var(--thm-base);
}

.banner-section__home-two .banner-carousel .slide-item {
	padding-top: 175px;
	padding-bottom: 175px;
}

.banner-section__home-three .banner-carousel .slide-item {
	padding-top: 335px;
	padding-bottom: 210px;
}

.banner-section__home-three .owl-theme .owl-nav .owl-prev {
	left: 20px;
	outline: none;
}

.banner-section__home-three .owl-theme .owl-nav .owl-next {
	right: 20px;
	outline: none;
}

/*-------------------------------------------------------------- 
# Exhibitions
--------------------------------------------------------------*/

.exhibition-one {
	padding-bottom: 120px;
}

.exhibition-one .row.high-gutter {
	margin-left: -53px;
	margin-right: -53px;
}

.exhibition-one .row.high-gutter>[class*="col-"] {
	padding-left: 53px;
	padding-right: 53px;
}

.exhibition-one__single {
	margin-bottom: 30px;
}

.exhibition-one__image {
	overflow: hidden;
	position: relative;
}

.exhibition-one__image::after {
	content: "";
	width: 150%;
	height: 200%;
	position: absolute;
	top: -100%;
	left: -180%;
	z-index: 11;
	-webkit-transition: 1s;
	transition: 1s;
	-webkit-transform: rotate(30deg) scale(1.2);
	        transform: rotate(30deg) scale(1.2);
	background: -webkit-gradient(linear,
			left top, right top,
			color-stop(50%, rgba(255, 255, 255, 0)),
			to(rgba(255, 255, 255, 0.7)));
	background: linear-gradient(90deg,
			rgba(255, 255, 255, 0) 50%,
			rgba(255, 255, 255, 0.7) 100%);
}

.exhibition-one__single:hover .exhibition-one__image::after {
	top: 100%;
	left: 100%;
	-webkit-transform: rotate(0);
	        transform: rotate(0);
}

.exhibition-one__image>img {
	width: 100%;
	box-shadow: 0px 20px 50px 0px rgba(33, 37, 43, 0.1);
}

.exhibition-one__content {
	text-align: center;
}

.exhibition-one__content h3 {
	margin: 0;
	font-size: 24px;
	line-height: 36px;
	color: var(--thm-black);
	font-weight: bold;
	margin-top: 20px;
	margin-bottom: 15px;
	width: 65%;
	margin-left: auto;
	margin-right: auto;
}

.exhibition-one__content h3 a {
	color: inherit;
	-webkit-transition: all 500ms ease;
	transition: all 500ms ease;
}

.exhibition-one__content h3 a:hover {
	color: var(--thm-base);
}

.exhibition-one__content p {
	margin: 0;
	font-size: 16px;
	text-transform: uppercase;
	color: var(--thm-base);
}

/* exhibition two */

.exhibition-two {
	padding-bottom: 340px;
}

.exhibition-two__content {
	box-shadow: 0px 20px 50px 0px rgba(33, 37, 43, 0.1);
	padding-left: 40px;
	padding-right: 40px;
	padding-bottom: 40px;
}

.exhibition-two__content h3 {
	margin: 0;
	padding-top: 30px;
	margin-bottom: 10px;
	font-size: 24px;
	line-height: 36px;
	font-weight: bold;
	color: var(--thm-black);
}

.exhibition-two__content h3 a {
	color: inherit;
	-webkit-transition: 500ms;
	transition: 500ms;
}

.exhibition-two__content h3 a:hover {
	color: var(--thm-base);
}

.exhibition-two__content span {
	font-size: 16px;
	color: var(--thm-base);
	text-transform: uppercase;
	display: block;
	margin-bottom: 15px;
}

.exhibition-two__content p {
	margin: 0;
	color: #848484;
	font-size: 16px;
	line-height: 28px;
	margin-bottom: 12px;
}

.exhibition-two__link {
	color: #252930;
	font-size: 16px;
	font-weight: bold;
	-webkit-transition: all 500ms ease;
	transition: all 500ms ease;
	position: relative;
}

.exhibition-two__link::before {
	content: "";
	width: 100%;
	height: 1px;
	background-color: var(--thm-base);
	position: absolute;
	bottom: 0;
	left: 0;
	-webkit-transform: scale(0, 1);
	        transform: scale(0, 1);
	-webkit-transform-origin: left center;
	        transform-origin: left center;
	-webkit-transition: -webkit-transform 500ms ease;
	transition: -webkit-transform 500ms ease;
	transition: transform 500ms ease;
	transition: transform 500ms ease, -webkit-transform 500ms ease;
}

.exhibition-two__link:hover {
	color: var(--thm-base);
}

.exhibition-two__link:hover::before {
	-webkit-transform: scale(1, 1);
	        transform: scale(1, 1);
	-webkit-transform-origin: right center;
	        transform-origin: right center;
}


.exhibition-two__image {
	overflow: hidden;
	position: relative;
}

.exhibition-two__image::after {
	content: "";
	width: 150%;
	height: 200%;
	position: absolute;
	top: -100%;
	left: -190%;
	z-index: 11;
	-webkit-transition: 1s;
	transition: 1s;
	-webkit-transform: rotate(30deg) scale(1.3);
	        transform: rotate(30deg) scale(1.3);
	background: -webkit-gradient(linear,
			left top, right top,
			color-stop(50%, rgba(255, 255, 255, 0)),
			to(rgba(255, 255, 255, 0.7)));
	background: linear-gradient(90deg,
			rgba(255, 255, 255, 0) 50%,
			rgba(255, 255, 255, 0.7) 100%);
}

.exhibition-two__single:hover .exhibition-two__image::after {
	top: 100%;
	left: 100%;
	-webkit-transform: rotate(0);
	        transform: rotate(0);
}

.exhibition-two__carousel.owl-theme .owl-nav {
	display: -webkit-box;
	display: flex;
	-webkit-box-align: center;
	        align-items: center;
	-webkit-box-pack: center;
	        justify-content: center;
}

.exhibition-two__carousel.owl-theme .owl-nav {
	margin-top: 70px;
}

.exhibition-two__carousel.owl-theme .owl-nav [class*=owl-] {
	width: 60px;
	height: 45px;
	border: 1px solid var(--thm-base);
	opacity: 50%;
	display: -webkit-box;
	display: flex;
	-webkit-box-align: center;
	        align-items: center;
	-webkit-box-pack: center;
	        justify-content: center;
	-webkit-transition: 500ms;
	transition: 500ms;
	position: relative;
	outline: none;
	margin: 0;
	padding: 0;
}

.exhibition-two__carousel.owl-theme .owl-nav [class*=owl-]:hover {
	border-color: var(--thm-base);
	background-color: transparent;
	opacity: 100%;
}


.exhibition-two__carousel.owl-theme .owl-nav [class*=owl-]+[class*=owl-] {
	margin-left: 10px;
}

.exhibition-two__carousel.owl-theme .owl-nav [class*=owl-] span {
	color: rgba(0, 0, 0, 0);
	display: -webkit-box;
	display: flex;
	-webkit-box-align: center;
	        align-items: center;
	-webkit-box-pack: center;
	        justify-content: center;
	position: relative;
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	text-align: center;
}


.exhibition-two__carousel.owl-theme .owl-nav [class*=owl-] span::before {
	content: '\f30a';
	color: var(--thm-base);
	font-size: 17px;
	font-family: "Font Awesome 5 Free";
	font-weight: 900;
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	text-align: center;
	display: -webkit-box;
	display: flex;
	-webkit-box-align: center;
	        align-items: center;
	-webkit-box-pack: center;
	        justify-content: center;
}

.exhibition-two__carousel.owl-theme .owl-nav [class*=owl-]:nth-child(2) span::before {
	content: '\f30b';
}




/*-------------------------------------------------------------- 
# Collections
--------------------------------------------------------------*/

.collection-one {
	padding-top: 150px;
	padding-bottom: 150px;
	background-repeat: no-repeat;
	background-position: center center;
	background-size: cover;
}

.collection-one__top {
	display: -webkit-box;
	display: flex;
	-webkit-box-align: center;
	        align-items: center;
	-webkit-box-pack: justify;
	        justify-content: space-between;
}

.collection-one__top .block-title h3 {
	color: #fff;
}

.collection-one__top .more-post__link {
	color: #fff;
}

.collection-one__carousel {
	width: 100%;
	max-width: 1745px;
	margin-left: auto;
	margin-right: -200px;
	margin-top: 90px;
}

@media (max-width: 1750px) {
	.collection-one__carousel {
		max-width: 1660px;
	}
}

@media (max-width: 1440px) {
	.collection-one__carousel {
		margin-right: -140px;
	}
}

@media (max-width: 1366px) {
	.collection-one__carousel {
		margin-right: -90px;
	}
}

.collection-one__single {
	padding-left: 190px;
	position: relative;
}

@media (max-width: 1440px) {
	.collection-one__single {
		padding-left: 120px;
	}
}

.collection-one__single>img {
	-webkit-transition: 0.5s;
	transition: 0.5s;
	-webkit-clip-path: polygon(0 10%, 100% 0, 100% 100%, 0 90%);
	        clip-path: polygon(0 10%, 100% 0, 100% 100%, 0 90%);
}

.collection-one__single:hover>img {
	-webkit-clip-path: polygon(0 0%, 100% 10%, 100% 90%, 0 100%);
	        clip-path: polygon(0 0%, 100% 10%, 100% 90%, 0 100%);
}

.collection-one__content {
	position: absolute;
	bottom: 80px;
	left: 0;
	width: 100%;
}

.collection-one__content h3 {
	margin: 0;
	font-size: 26px;
	line-height: 36px;
	font-weight: bold;
	color: #fff;
	width: 50%;
}

@media (max-width: 1440px) {
	.collection-one__content h3 {
		width: 70%;
	}
}

.collection-one__content h3 a {
	color: inherit;
	-webkit-transition: all 500ms ease;
	transition: all 500ms ease;
}

.collection-one__content h3 a:hover {
	color: var(--thm-base);
}

/* featured collection */

.featured-collection {
	padding-top: 150px;
	padding-bottom: 200px;
}

.featured-collection__left {
	padding-right: 50px;
}

.featured-collection__left .featured-collection__image {
	margin-bottom: 80px;
}

.featured-collection__left>p {
	margin: 0;
}

.featured-collection__btn {
	margin-top: 40px;
}

.featured-collection__btn:hover {
	background-color: var(--thm-black);
	color: #fff;
}

.featured-collection__right {
	padding-left: 50px;
	padding-top: 100px;
}

.featured-collection__right>p {
	margin: 0;
	color: var(--thm-black);
	font-family: var(--heading-font);
	font-size: 24px;
	line-height: 44px;
	margin-bottom: 85px;
}

.featured-collection__right .block-title {
	margin-bottom: 50px;
}

.featured-collection__image>img {
	max-width: 100%;
	box-shadow: 0px 20px 50px 0px rgba(33, 37, 43, 0.1);
}

.featured-collection__image {
	overflow: hidden;
	position: relative;
}

.featured-collection__image::after {
	content: "";
	width: 150%;
	height: 200%;
	position: absolute;
	top: -120%;
	left: -210%;
	z-index: 11;
	-webkit-transition: 1s;
	transition: 1s;
	-webkit-transform: rotate(30deg) scale(1.5);
	        transform: rotate(30deg) scale(1.5);
	background: -webkit-gradient(linear,
			left top, right top,
			color-stop(50%, rgba(255, 255, 255, 0)),
			to(rgba(255, 255, 255, 0.7)));
	background: linear-gradient(90deg,
			rgba(255, 255, 255, 0) 50%,
			rgba(255, 255, 255, 0.7) 100%);
}

.featured-collection__image:hover::after {
	top: 100%;
	left: 100%;
	-webkit-transform: rotate(0);
	        transform: rotate(0);
}

/* collection two */

.collection-two {
	padding-bottom: 200px;
	background-image: -webkit-gradient(linear, left top, left bottom, from(transparent), to(rgba(245, 247, 250, .8)));
	background-image: linear-gradient(180deg, transparent 0%, rgba(245, 247, 250, .8) 100%);
	padding-top: 165px;
}

.collection-two .container-fluid {
	padding-left: 0;
	padding-right: 0;
}

.collection-two__single {
	position: relative;
}

.collection-two__content {
	padding-top: 32.5px;
	padding-bottom: 32.5px;
	position: absolute;
	bottom: 0;
	left: 50%;
	-webkit-transform: translateX(-50%) translateY(50%);
	        transform: translateX(-50%) translateY(50%);
	width: calc(100% - 70px);
	background-color: #fff;
	text-align: center;
	box-shadow: 0px 20px 50px 0px rgba(33, 37, 43, 0.1);
	-webkit-transition: 500ms;
	transition: 500ms;
	z-index: 11;
}

.collection-two__content:hover {
	background-color: var(--thm-base);
}

.collection-two__content h3 {
	color: var(--thm-black);
	font-size: 20px;
	line-height: 30px;
	margin: 0;
	font-weight: bold;
}

.collection-two__content:hover h3 a {
	color: #fff;
}

.collection-two__content h3 a {
	color: inherit;
	-webkit-transition: 500ms;
	transition: 500ms;
}

.collection-two__content h3 a:hover {
	color: #fff;
}

.collection-two__content p {
	margin: 0;
	text-transform: uppercase;
	font-size: 16px;
	color: var(--thm-base);
	line-height: 1em;
	margin-top: 20px;
	-webkit-transition: 500ms;
	transition: 500ms;
}

.collection-two__content:hover p {
	color: #fff;
}

.collection-two__carousel.owl-theme .owl-nav {
	display: -webkit-box;
	display: flex;
	-webkit-box-align: center;
	        align-items: center;
	-webkit-box-pack: center;
	        justify-content: center;
}

.collection-two__carousel.owl-theme .owl-nav {
	margin-top: 160px;
}

.collection-two__carousel.owl-theme .owl-nav [class*=owl-] {
	width: 60px;
	height: 45px;
	border: 1px solid var(--thm-base);
	opacity: 50%;
	display: -webkit-box;
	display: flex;
	-webkit-box-align: center;
	        align-items: center;
	-webkit-box-pack: center;
	        justify-content: center;
	-webkit-transition: 500ms;
	transition: 500ms;
	position: relative;
	outline: none;
	margin: 0;
	padding: 0;
}

.collection-two__carousel.owl-theme .owl-nav [class*=owl-]:hover {
	border-color: var(--thm-base);
	background-color: transparent;
	opacity: 100%;
}


.collection-two__carousel.owl-theme .owl-nav [class*=owl-]+[class*=owl-] {
	margin-left: 10px;
}

.collection-two__carousel.owl-theme .owl-nav [class*=owl-] span {
	color: rgba(0, 0, 0, 0);
	display: -webkit-box;
	display: flex;
	-webkit-box-align: center;
	        align-items: center;
	-webkit-box-pack: center;
	        justify-content: center;
	position: relative;
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	text-align: center;
}


.collection-two__carousel.owl-theme .owl-nav [class*=owl-] span::before {
	content: '\f30a';
	color: var(--thm-base);
	font-size: 17px;
	font-family: "Font Awesome 5 Free";
	font-weight: 900;
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	text-align: center;
	display: -webkit-box;
	display: flex;
	-webkit-box-align: center;
	        align-items: center;
	-webkit-box-pack: center;
	        justify-content: center;
}

.collection-two__carousel.owl-theme .owl-nav [class*=owl-]:nth-child(2) span::before {
	content: '\f30b';
}


.collection-two__image {
	overflow: hidden;
	position: relative;
}

.collection-two__image::after {
	content: "";
	width: 150%;
	height: 200%;
	position: absolute;
	top: -100%;
	left: -180%;
	z-index: 11;
	-webkit-transition: 1s;
	transition: 1s;
	-webkit-transform: rotate(30deg) scale(1.2);
	        transform: rotate(30deg) scale(1.2);
	background: -webkit-gradient(linear,
			left top, right top,
			color-stop(50%, rgba(255, 255, 255, 0)),
			to(rgba(255, 255, 255, 0.7)));
	background: linear-gradient(90deg,
			rgba(255, 255, 255, 0) 50%,
			rgba(255, 255, 255, 0.7) 100%);
}

.collection-two__single:hover .collection-two__image::after {
	top: 100%;
	left: 100%;
	-webkit-transform: rotate(0);
	        transform: rotate(0);
}

.collection-two__image>img {
	width: 100%;
	box-shadow: 0px 20px 50px 0px rgba(33, 37, 43, 0.1);
}

/* collection two full width page */

.collection-two__collection-full-page {
	padding-top: 0;
	padding-bottom: 150px;
}

/* collection two home three */

.collection-two__home-three {
	padding-top: 0;
	padding-bottom: 150px;
	position: relative;
	z-index: 10;
	background-image: none;
}

.collection-two__scupture {
	position: absolute;
	bottom: -45px;
	right: 2%;
	z-index: 10;
}

.collection-two__home-three .collection-two__content h3 {
	font-size: 24px;
}

.collection-two__home-three .collection-two__content {
	width: calc(100% - 40px);
	box-shadow: none;
}

.collection-two__home-three .collection-two__image img {
	box-shadow: 0px 20px 50px 0px rgba(33, 37, 43, 0.1);
}

/* collection three */

.collection-three {
	padding-top: 150px;
	padding-bottom: 80px;
}

.collection-three__block>p {
	font-size: 16px;
	line-height: 28px;
	color: #848484;
	margin: 0;
	margin-top: 30px;
	margin-bottom: 35px;
}

.collection-three__block-btn {
	border: 2px solid #e5e5e5;
	background-color: #fff;
	color: var(--thm-black);
}

.collection-three__block-btn:hover {
	color: #fff;
	background-color: var(--thm-black);
	border-color: var(--thm-black);
}

.collection-three .collection-two__single {
	margin-bottom: 50px;
}

.collection-three .masonary-layout {
	margin-left: -20px;
	margin-right: -20px;
}

.collection-three .masonary-item {
	padding-left: 20px;
	padding-right: 20px;
}

.collection-three .collection-two__content {
	position: relative;
	width: 100%;
	padding: 0;
	text-align: left;
	background-color: #fff !important;
	box-shadow: none;
	bottom: auto;
	left: auto;
	-webkit-transform: translateX(0) translateY(0);
	        transform: translateX(0) translateY(0);
	padding-top: 20px;
}

.collection-three .collection-two__content h3 {
	color: var(--thm-black) !important;
}

.collection-three .collection-two__single:hover .collection-two__content h3 a,
.collection-three .collection-two__content h3 a:hover {
	color: var(--thm-base) !important;
}

.collection-three .collection-two__content h3 br {
	display: none;
}

.collection-three .collection-two__content p {
	color: var(--thm-base) !important;
}


/* collection grid */

.collection-grid {
	padding-bottom: 100px;
}

.collection-grid .row.high-gutter {
	margin-left: -20px;
	margin-right: -20px;
}

.collection-grid .row.high-gutter>[class*=col-] {
	padding-left: 20px;
	padding-right: 20px;
}

.collection-grid__single {
	margin-bottom: 50px;
}

.collection-grid__image {
	position: relative;
	background-color: var(--thm-black);
	overflow: hidden;
}

.collection-grid__image>img {
	width: 100%;
	-webkit-transition: opacity 500ms, -webkit-transform 500ms;
	transition: opacity 500ms, -webkit-transform 500ms;
	transition: transform 500ms, opacity 500ms;
	transition: transform 500ms, opacity 500ms, -webkit-transform 500ms;
	-webkit-transform: scale(1.0);
			transform: scale(1.0);
		min-height: 300px;
		object-fit: cover;
		max-height: 300px;
}

.collection-grid__single:hover .collection-grid__image>img {
	opacity: 0.5;
	-webkit-transform: scale(1.05);
	        transform: scale(1.05);
}

.collection-grid__image>a {
	width: 65px;
	height: 65px;
	display: -webkit-box;
	display: flex;
	-webkit-box-pack: center;
	        justify-content: center;
	-webkit-box-align: center;
	        align-items: center;
	text-align: center;
	position: absolute;
	top: calc(50% - 32.5px);
	left: calc(50% - 32.5px);
	background-color: #fff;
	color: var(--thm-black);
	line-height: 65px;
	font-size: 30px;
	font-weight: 600px;
	color: var(--thm-black);
	-webkit-transform: translateY(33px) rotate(30deg);
	        transform: translateY(33px) rotate(30deg);
	opacity: 0;
	visibility: hidden;
	-webkit-transition: 500ms;
	transition: 500ms;
	-webkit-transition-delay: 0ms;
	        transition-delay: 0ms;
}

.collection-grid__single:hover .collection-grid__image>a {
	visibility: visible;
	opacity: 1;
	-webkit-transform: translateY(0) rotate(0);
	        transform: translateY(0) rotate(0);
	-webkit-transition-delay: 500ms;
	        transition-delay: 500ms;
}

.collection-grid__content h3 {
	margin: 0;
	font-size: 20px;
	font-weight: bold;
	color: var(--thm-black);
	margin-top: 30px;
	margin-bottom: 20px;
}

.collection-grid__content h3 a {
	// color: inherit;
	color: #757575;
	-webkit-transition: 500ms;
	transition: 500ms;
}

.collection-grid__content h3 a:hover {
	color: var(--thm-base);
}

.collection-grid__content p {
	margin: 0;
	color: var(--thm-base);
	font-size: 16px;
	line-height: 1em;
	text-transform: uppercase;
}

/* collection filter */

.collection-filter {
	display: -webkit-box;
	display: flex;
	-webkit-box-pack: center;
	        justify-content: center;
	-webkit-box-align: center;
	        align-items: center;
	margin-bottom: 60px;
	flex-wrap: wrap;
}

.collection-filter li {
	display: -webkit-box;
	display: flex;
	-webkit-box-align: center;
	        align-items: center;
	margin-bottom: 10px;
}

.collection-filter li+li {
	margin-left: 10px;
}

.collection-filter li span {
	display: block;
	border: 1px solid #e5e5e5;
	font-size: 16px;
	color: var(--thm-black);
	padding: 10px 26px;
	cursor: pointer;
	-webkit-transition: 500ms;
	transition: 500ms;
}

.collection-filter li.active span,
.collection-filter li:hover span {
	background-color: var(--thm-base);
	border-color: var(--thm-base);
	color: #fff;
}

/* collection masonary */

.collection-masonary {
	padding-top: 150px;
}

.collection-masonary .collection-grid__top {
	display: -webkit-box;
	display: flex;
	-webkit-box-align: end;
	        align-items: flex-end;
	-webkit-box-pack: justify;
	        justify-content: space-between;
	margin-bottom: 60px;
}

@media(max-width: 1199px) {
	.collection-masonary .collection-grid__top {
		-webkit-box-orient: vertical;
		-webkit-box-direction: normal;
		        flex-direction: column;
		-webkit-box-pack: start;
		        justify-content: flex-start;
		-webkit-box-align: start;
		        align-items: flex-start;
	}
}

.collection-masonary .collection-grid__top .block-title {
	margin-bottom: 0;
}

@media(max-width: 1199px) {
	.collection-masonary .collection-grid__top .block-title {
		margin-bottom: 40px;
	}
}

.collection-masonary .collection-filter {
	margin-bottom: 0;
}

/*-------------------------------------------------------------- 
# Blog
--------------------------------------------------------------*/

.blog-one {
	padding-top: 150px;
	padding-bottom: 120px;
}

.blog-one__top {
	display: -webkit-box;
	display: flex;
	-webkit-box-align: center;
	        align-items: center;
	-webkit-box-pack: justify;
	        justify-content: space-between;
	margin-bottom: 55px;
}

.blog-one__single {
	margin-bottom: 30px;
}

.blog-one__image {
	overflow: hidden;
	background-color: var(--thm-black);
}

.blog-one__image>img {
	width: 100%;
	-webkit-transform: scale(1);
	        transform: scale(1);
	opacity: 1;
	-webkit-transition: all 500ms ease;
	transition: all 500ms ease;
}

.blog-one__single:hover .blog-one__image>img {
	opacity: 0.5;
	-webkit-transform: scale(1.05);
	        transform: scale(1.05);
}

.blog-one__date {
	display: inline-block;
	vertical-align: middle;
	color: #252930;
	line-height: 45px;
	padding-left: 20px;
	padding-right: 20px;
	background-color: #ffffff;
	position: absolute;
	top: 40px;
	left: 40px;
	text-transform: uppercase;
}

.blog-one__date i {
	color: var(--thm-base);
	margin-right: 4px;
}

.blog-one__content {
	position: relative;
	padding-top: 40px;
	padding-bottom: 45px;
	padding-left: 40px;
	padding-right: 40px;
	box-shadow: 0px 20px 50px 0px rgba(33, 37, 43, 0.1);
}

.blog-one__content::before {
	content: "";
	width: 2px;
	height: 40px;
	background-color: var(--thm-base);
	position: absolute;
	top: -20px;
	right: 40px;
	z-index: 10;
	-webkit-transition: all 500ms ease;
	transition: all 500ms ease;
}

.blog-one__single:hover .blog-one__content::before {
	-webkit-transform: rotate(90deg);
	        transform: rotate(90deg);
}

.blog-one__meta {
	display: -webkit-box;
	display: flex;
	-webkit-box-align: center;
	        align-items: center;
	margin: 0;
	margin-bottom: 5px;
}

.blog-one__meta li {
	display: -webkit-box;
	display: flex;
	-webkit-box-align: center;
	        align-items: center;
	color: var(--thm-base);
	text-transform: uppercase;
	font-size: 16px;
}

.blog-one__meta li a {
	color: inherit;
}

.blog-one__meta li+li:before {
	content: "|";
	margin-left: 14px;
	margin-right: 14px;
}

.blog-one__content h3 {
	margin: 0;
	font-size: 24px;
	line-height: 34px;
	color: var(--thm-black);
	margin-bottom: 15px;
}

.blog-one__content h3 a {
	color: inherit;
	-webkit-transition: 500ms;
	transition: 500ms;
}

.blog-one__content h3 a:hover {
	color: var(--thm-base);
}

.blog-one__content p {
	margin: 0;
	font-size: 16px;
	line-height: 28px;
	color: #848484;
	margin-bottom: 10px;
}

.blog-one__link {
	color: #252930;
	font-size: 16px;
	font-weight: bold;
	-webkit-transition: all 500ms ease;
	transition: all 500ms ease;
	position: relative;
	display: inline-block;
}

.blog-one__link::before {
	content: "";
	width: 100%;
	height: 1px;
	background-color: var(--thm-base);
	position: absolute;
	bottom: 0;
	left: 0;
	-webkit-transform: scale(0, 1);
	        transform: scale(0, 1);
	-webkit-transform-origin: left center;
	        transform-origin: left center;
	-webkit-transition: -webkit-transform 500ms ease;
	transition: -webkit-transform 500ms ease;
	transition: transform 500ms ease;
	transition: transform 500ms ease, -webkit-transform 500ms ease;
}

.blog-one__link:hover {
	color: var(--thm-base);
}

.blog-one__link:hover::before {
	-webkit-transform-origin: right center;
	        transform-origin: right center;
	-webkit-transform: scale(1, 1);
	        transform: scale(1, 1);
}

/* blog one video */

.blog-one__video {
	position: relative;
	background-color: #212224;
}

.blog-one__video::before {
	content: '';
	width: 300px;
	height: 300px;
	border: 2px solid #ffffff;
	opacity: 0.5;
	position: absolute;
	top: 50%;
	left: 50%;
	-webkit-transform: translateX(-50%) translateY(-50%);
	        transform: translateX(-50%) translateY(-50%);
}

.blog-one__video>img {
	width: 100%;
	opacity: 0.3;
}

.blog-one__video-btn {
	position: absolute;
	top: 50%;
	left: 50%;
	-webkit-transform: translateY(-50%) translateX(-50%);
	        transform: translateY(-50%) translateX(-50%);
	width: 90px;
	height: 90px;
	border-radius: 50%;
	text-align: center;
	display: -webkit-box;
	display: flex;
	-webkit-box-align: center;
	        align-items: center;
	-webkit-box-pack: center;
	        justify-content: center;
	color: #FFF;
	font-size: 21px;
	-webkit-transition: 500ms;
	transition: 500ms;
	background-color: var(--thm-base);
}

.blog-one__video-btn:hover {
	background-color: #fff;
	color: var(--thm-black);
}

/* blog standard page */

.blog-standard-page .blog-one__content {
	padding-left: 0;
	padding-right: 0;
	box-shadow: none;
	padding-bottom: 0;
}

.blog-standard-page .blog-one__content::before {
	display: none;
}

.blog-standard-page .blog-one__single {
	margin-bottom: 50px;
}

/* blog masonary */

.blog-masonary__single {
	margin-bottom: 60px;
}

.blog-masonary__single .blog-one__image {
	margin-bottom: 30px;
}

.blog-masonary__qoute {
	background-color: var(--thm-base);
	padding-left: 40px;
	padding-right: 40px;
	padding-top: 50px;
	padding-bottom: 55px;
	margin-bottom: 70px;
}

.blog-masonary__qoute p {
	margin: 0;
	color: #fff;
	font-size: 22px;
	line-height: 40px;
	font-family: var(--heading-font);
	font-style: italic;
}

.blog-masonary__qoute h3 {
	text-transform: uppercase;
	font-family: var(--thm-font);
	margin: 0;
	color: #fff;
	font-size: 16px;
	display: -webkit-box;
	display: flex;
	-webkit-box-align: center;
	        align-items: center;
	margin-top: 45px;
}

.blog-masonary__qoute h3::before {
	content: '';
	width: 40px;
	height: 2px;
	background-color: #fff;
	margin-right: 10px;
}

.blog-masonary__single h3 {
	margin: 0;
	font-size: 24px;
	line-height: 34px;
	color: var(--thm-black);
	font-weight: bold;
}

.blog-masonary__single h3 a {
	color: inherit;
	-webkit-transition: 500ms;
	transition: 500ms;
}

.blog-masonary__single h3 a:hover {
	color: var(--thm-base);
}

.blog-masonary__single .blog-one__meta {
	margin-bottom: 30px;
	margin-top: 5px;
}

/* blog details */

.blog-details__image {
	margin-bottom: 40px;
}

.blog-details__main h3 {
	margin: 0;
	color: var(--thm-black);
	font-size: 44px;
	line-height: 55px;
	font-weight: bold;
}

.blog-details__main p {
	margin: 0;
	color: #848484;
	font-size: 16px;
	line-height: 28px;
}

.blog-details__main h4 {
	margin: 0;
	color: #252930;
	font-size: 24px;
	font-weight: bold;
}

.blog-details__main .blog-details__tags {
	font-size: 17px;
	display: -webkit-box;
	display: flex;
	-webkit-box-align: center;
	        align-items: center;
}

.blog-details__tags span {
	margin-right: 5px;
}

.blog-details__tags span,
.blog-details__tags a {
	color: #252930;
	-webkit-transition: 500ms;
	transition: 500ms;
}

.blog-details__tags a+a {
	margin-left: 5px;
}

.blog-details__tags a:hover {
	color: var(--thm-base);
}

.blog-details__share {
	display: -webkit-box;
	display: flex;
	-webkit-box-align: center;
	        align-items: center;
	color: var(--thm-black);
}

.blog-details__share span {
	margin-right: 10px;
}

.blog-details__share a {
	font-size: 16px;
	color: #c2c2c2;
	-webkit-transition: 500ms;
	transition: 500ms;
}

.blog-details__share a+a {
	margin-left: 10px;
}

.blog-details__share a:hover {
	color: var(--thm-base);
}

.blog-details__main .blog-details__tags {
	margin-bottom: 15px;
	margin-top: 45px;
}

/*-------------------------------------------------------------- 
# Comment
--------------------------------------------------------------*/

.comment-one {
	margin-top: 50px;
	margin-bottom: 50px;
}

.comment-one__title {
	margin: 0;
	color: var(--thm-black);
	font-size: 24px;
	font-weight: bold;
	margin-bottom: 40px;
}

.comment-one__single {
	display: -webkit-box;
	display: flex;
}

.comment-one__image>img {
	width: 80px;
	border-radius: 50%;
}

.comment-one__content {
	padding-left: 30px;
}

.comment-one__content h3 {
	margin: 0;
	font-size: 24px;
	color: var(--thm-black);
	font-weight: bold;
	margin-bottom: 5px;
}

.comment-one__btn {
	margin: 0;
	padding: 2px 15px;
	color: var(--thm-black);
	background-color: #fff;
	border: 1px solid #e5e5e5;
	font-size: 15px;
	position: absolute;
	top: 0;
	right: 0;
}

.comment-one__btn:hover {
	color: #fff;
	border-color: var(--thm-base);
	background-color: var(--thm-base);
}

.comment-one__single {
	position: relative;
}

.comment-one__content span {
	margin: 0;
	color: #848484;
	display: block;
	margin-bottom: 15px;
}

.comment-one__content p {
	margin: 0;
	color: #848484;
	font-size: 16px;
	line-height: 28px;
}

.comment-one__single+.comment-one__single {
	border-top: 1px solid #e5e5e5;
	padding-top: 50px;
	margin-top: 40px;
}

.comment-one__single+.comment-one__single .comment-one__btn {
	top: 60px;
}

/*-------------------------------------------------------------- 
# Event
--------------------------------------------------------------*/

.event-one {
	padding-bottom: 150px;
	position: relative;
	background-image: -webkit-gradient(linear,
			left top, right top,
			from(rgba(245, 247, 250, 0)),
			to(rgba(245, 247, 250, 0.8)));
	background-image: linear-gradient(90deg,
			rgba(245, 247, 250, 0) 0%,
			rgba(245, 247, 250, 0.8) 100%);
}

.event-one::before {
	content: "";
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	opacity: 0.3;
	background-position: center center;
	background-repeat: no-repeat;
	background-size: cover;
	background-image: url(./images/backgrounds/event-bg-1-1.jpg);
}

.event-one__moc {
	position: absolute;
	top: -150px;
	right: 2%;
	-webkit-animation: ImgBounce 5s ease-in-out 0s infinite alternate;
	        animation: ImgBounce 5s ease-in-out 0s infinite alternate;
}

.event-one__home-three .event-one__moc {
	left: 2%;
	right: auto;
}

.event-one .container {
	position: relative;
}

.event-one .container .row>[class*="col-"]+[class*="col-"] {
	margin-top: 30px;
}

.event-one__single {
	background-color: #fff;
	display: -webkit-box;
	display: flex;
	-webkit-box-align: center;
	        align-items: center;
	padding-left: 80px;
	padding-right: 80px;
	flex-wrap: wrap;
	padding-top: 55.5px;
	padding-bottom: 55.5px;
	-webkit-transition: all 500ms ease;
	transition: all 500ms ease;
}

.event-one__single:hover {
	box-shadow: 0px 20px 50px 0px rgba(33, 37, 43, 0.1);
}

.event-one__image {
	display: -webkit-box;
	display: flex;
	-webkit-box-align: center;
	        align-items: center;
	position: relative;
}

.event-one__image-inner {
	position: relative;
}

.event-one__image-inner::before {
	content: "";
	width: 260px;
	height: 10px;
	border-top: 2px solid var(--thm-base);
	border-bottom: 2px solid var(--thm-base);
	opacity: 0.5;
	position: absolute;
	top: 50%;
	left: 50%;
	-webkit-transform: translate(-50%, -50%) rotate(-45deg) skewX(45deg);
	        transform: translate(-50%, -50%) rotate(-45deg) skewX(45deg);
	-webkit-transition: -webkit-transform 500ms ease;
	transition: -webkit-transform 500ms ease;
	transition: transform 500ms ease;
	transition: transform 500ms ease, -webkit-transform 500ms ease;
}

.event-one__single:hover .event-one__image-inner::before {
	-webkit-transform: translate(-50%, -50%) rotate(45deg) skewX(45deg);
	        transform: translate(-50%, -50%) rotate(45deg) skewX(45deg);
}

.event-one__image-inner>img {
	position: relative;
	z-index: 10;
	border-radius: 50%;
	box-shadow: 0px 10px 20px 0px rgba(33, 37, 43, 0.2);
}

.event-one__content {
	padding-left: 80px;
	padding-right: 90px;
	-webkit-box-flex: 1;
	        flex: 1 1 54%;
}

.event-one__content h3 {
	margin: 0;
	margin-bottom: 15px;
	font-size: 24px;
	font-weight: bold;
	color: var(--thm-black);
}

.event-one__content h3 a {
	color: inherit;
	-webkit-transition: all 500ms ease;
	transition: all 500ms ease;
}

.event-one__content h3 a:hover {
	color: var(--thm-base);
}

.event-one__content p {
	margin: 0;
	width: 87%;
}

.event-one__date {
	font-size: 20px;
	color: var(--thm-base);
	line-height: 1em;
	text-align: center;
	margin-right: 85px;
}

.event-one__date span {
	display: block;
	font-size: 47px;
	font-weight: bold;
	color: var(--thm-black);
	margin-bottom: 15px;
}

.event-one__btn {
	border: 2px solid #e5e5e5;
	background-color: transparent;
	font-size: 16px;
	color: var(--thm-black);
}

.event-one__btn:hover {
	background-color: var(--thm-base);
	border-color: var(--thm-base);
	color: #fff;
}

.event-one__event-page-2 {
	padding-top: 150px;
	background-image: -webkit-gradient(linear, left top, right top, from(rgba(245, 247, 250, 0)), to(rgba(245, 247, 250, 0.8)));
	background-image: linear-gradient(90deg, rgba(245, 247, 250, 0) 0%, rgba(245, 247, 250, 0.8) 100%);
}

.event-one__event-page-2::before {
	display: none;
}

.event-one__event-page-2 .more-post__block {
	margin-top: 70px;
}

/* event two */

.event-two {
	padding-bottom: 150px;
	position: relative;
}

.event-two__sculpture {
	position: absolute;
	top: -120px;
	right: 3%;
	-webkit-animation: ImgBounce 5s ease-in-out 0s infinite alternate;
	        animation: ImgBounce 5s ease-in-out 0s infinite alternate;
}

.event-two .container {
	position: relative;
}

.event-two__single+.event-two__single {
	margin-top: 70px;
}

.event-two__single:nth-child(odd) .row {
	-webkit-box-orient: horizontal;
	-webkit-box-direction: reverse;
	        flex-direction: row-reverse;
}

.event-two__single:nth-child(even) .row .event-two__content {
	padding-left: 50px;
}

.event-two__single:nth-child(odd) .row .event-two__content {
	padding-right: 50px;
}

.event-two__image {
	position: relative;
}

.event-two__image::before {
	content: '';
	width: 250px;
	height: 202px;
	background-image: url(./images/shapes/event-2-dot-1.png);
	background-repeat: no-repeat;
	background-position: left top;
	position: absolute;
	top: 50%;
	-webkit-transform: translateY(-50%);
	        transform: translateY(-50%);
	left: -75px;
}


.event-two__single:nth-child(odd) .event-two__image::before {
	left: auto;
	right: -75px;
}

.event-two__image {
	display: inline-block;
	box-shadow: 0px 20px 50px 0px rgba(33, 37, 43, 0.2);
}

.event-two__image-inner>img {
	position: relative;
}

.event-two__image-inner {
	overflow: hidden;
	position: relative;
}

.event-two__image-inner::after {
	content: "";
	width: 150%;
	height: 200%;
	position: absolute;
	top: -100%;
	left: -180%;
	z-index: 11;
	-webkit-transition: 1s;
	transition: 1s;
	-webkit-transform: rotate(30deg) scale(1.2);
	        transform: rotate(30deg) scale(1.2);
	background: -webkit-gradient(linear,
			left top, right top,
			color-stop(50%, rgba(255, 255, 255, 0)),
			to(rgba(255, 255, 255, 0.7)));
	background: linear-gradient(90deg,
			rgba(255, 255, 255, 0) 50%,
			rgba(255, 255, 255, 0.7) 100%);
}

.event-two__single:hover .event-two__image-inner::after {
	top: 100%;
	left: 100%;
	-webkit-transform: rotate(0);
	        transform: rotate(0);
}


.event-two__content .block-title {
	margin-bottom: 40px;
}

.event-two__content .block-title p {
	font-size: 16px;
}

.event-two__content .block-title h3 {
	font-size: 30px;
	margin-top: 15px;
}

.event-two__content>p {
	margin: 0;
	color: #848484;
	font-size: 16px;
	line-height: 28px;
}

.event-two__btn {
	border: 2px solid #e5e5e5;
	background-color: transparent;
	color: #252930;
	margin-top: 40px;
}

.event-two__btn:hover {
	background-color: var(--thm-base);
	color: #fff;
	border-color: var(--thm-base);
}

.event-two__event-1-page {
	padding-top: 150px;
}

.event-two__event-1-page .more-post__block {
	margin-top: 70px;
}

/* event three */

.event-three {
	padding-top: 150px;
	padding-bottom: 150px;
}

.event-three .more-post__block {
	margin-top: 30px;
}

.event-three__single {
	margin-bottom: 40px;
}

.event-three__image {
	overflow: hidden;
	background-color: var(--thm-black);
}

.event-three__image>img {
	width: 100%;
	-webkit-transform: scale(1);
	        transform: scale(1);
	opacity: 1;
	-webkit-transition: all 500ms ease;
	transition: all 500ms ease;
}

.event-three__single:hover .event-three__image>img {
	opacity: 0.5;
	-webkit-transform: scale(1.05);
	        transform: scale(1.05);
}

.event-three__date {
	display: inline-block;
	vertical-align: middle;
	color: #252930;
	line-height: 45px;
	padding-left: 20px;
	padding-right: 20px;
	background-color: #ffffff;
	position: absolute;
	top: 40px;
	left: 40px;
	text-transform: uppercase;
}

.event-three__date i {
	color: var(--thm-base);
	margin-right: 4px;
}

.event-three__content {
	background-color: rgb(255, 255, 255);
	box-shadow: 0px 20px 50px 0px rgba(33, 37, 43, 0.1);
	padding-left: 30px;
	padding-right: 30px;
	padding-top: 30px;
	padding-bottom: 30px;
}

.event-three__content span {
	text-transform: uppercase;
	font-size: 16px;
	color: var(--thm-base);
	display: block;
	line-height: 1em;
}

.event-three__content h3 {
	margin: 0;
	font-size: 24px;
	line-height: 34px;
	color: var(--thm-black);
	font-weight: bold;
	margin-top: 10px;
	margin-bottom: 5px;
}

.event-three__content h3 a {
	color: inherit;
	-webkit-transition: 500ms;
	transition: 500ms;
}

.event-three__content h3 a:hover {
	color: var(--thm-base);
}

.event-three__content p {
	margin: 0;
	color: #848484;
	font-size: 16px;
	display: -webkit-box;
	display: flex;
	-webkit-box-align: center;
	        align-items: center;
	margin-bottom: 25px;
}

.event-three__content p i {
	margin-right: 10px;
}

.event-three__btn {
	padding: 0;
	background-color: #fff;
	border: 1px solid #e5e5e5;
	font-size: 16px;
	font-weight: bold;
	color: var(--thm-black);
	padding: 10px 37.5px;
}

.event-three__btn:hover {
	background-color: var(--thm-base);
	border-color: var(--thm-base);
	color: #fff;
}

/* event details */

.event-details {
	padding-top: 150px;
	padding-bottom: 150px;
}

.event-details__booking {
	background-image: url(./images/shapes/event-d-bg-1-1.jpg);
	background-repeat: no-repeat;
	background-position: bottom center;
	background-size: cover;
	background-color: var(--thm-black);
	padding-top: 60px;
	padding-bottom: 60px;
	padding-left: 40px;
	padding-right: 40px;
	box-shadow: 0px 20px 50px 0px rgba(33, 37, 43, 0.3);
}


.event-details__book-btn {
	padding: 11px 0;
	width: 100%;
	text-align: center;
	font-size: 16px;
	font-weight: bold;
}

.event-details__booking-info {
	margin: 0;
	margin-bottom: 55px;
}

.event-details__booking-info li {
	color: rgba(255, 255, 255, .5);
	font-size: 17px;
	line-height: 1em;
}

.event-details__booking-info li+li {
	margin-top: 25px;
}

.event-details__booking-info li span {
	display: block;
	color: #fefefe;
	font-size: 17px;
	text-transform: uppercase;
	margin: 0;
	line-height: 1em;
	margin-bottom: 15px;
}

.event-details .row.high-gutter {
	margin-left: -20px;
	margin-right: -20px;
}

.event-details .row.high-gutter>[class*=col-] {
	padding-left: 20px;
	padding-right: 20px;
}

.event-details__image {
	margin-bottom: 50px;
}

.event-details__image>img {
	width: 100%;
}

.event-details__content p {
	margin: 0;
}

.event-details__content p+p {
	margin-top: 25px;
}

.event-details__content h3 {
	font-weight: bold;
	font-size: 24px;
	color: var(--thm-black);
	margin: 0;
	margin-bottom: 25px;
}

.event-details__content p+h3 {
	margin-top: 30px;
}

.event-details__content .google-map__contact {
	box-shadow: 0px 20px 50px 0px rgba(33, 37, 43, 0.1);
	width: 100%;
	height: 350px;
	display: block;
	border: none;
	outline: none;
	margin-top: 40px;
	margin-bottom: 50px;
}

.event-details__contact {
	margin: 0;
	margin-top: 40px;
}

.event-details__contact li {
	font-size: 17px;
	line-height: 1em;
	color: #848484;
}

.event-details__contact li+li {
	margin-top: 25px;
}

.event-details__contact li span {
	display: block;
	color: var(--thm-black);
	font-size: 17px;
	text-transform: uppercase;
	margin: 0;
	line-height: 1em;
	margin-bottom: 15px;
}

.event-details__top {
	margin-bottom: 60px;
}

.event-details__top p {
	text-transform: uppercase;
	margin: 0;
	font-size: 17px;
	color: var(--thm-base);
	line-height: 1em;
	margin-bottom: 25px;
}

.event-details__top h3 {
	margin: 0;
	font-weight: bold;
	color: var(--thm-black);
	font-size: 44px;
}

.event-details__post-nav {
	border-top: 1px solid #e5e5e5;
	display: -webkit-box;
	display: flex;
	-webkit-box-align: center;
	        align-items: center;
	flex-wrap: wrap;
	margin-top: 65px;
	padding-top: 30px;
}

[class*=event-details__post__] {
	-webkit-box-flex: 1;
	        flex: 1 1 50%;
	position: relative;
	padding-left: 55px;
	background-image: url(./images/shapes/event-d-p-post.png);
	background-repeat: no-repeat;
	background-position: left center;
}

[class*=event-details__post__] p {
	margin: 0;
	color: #8e8e8e;
	font-size: 17px;
	text-transform: uppercase;
	line-height: 1em;
}

[class*=event-details__post__] h3 {
	margin: 0;
	color: var(--thm-black);
	font-size: 22px;
	font-weight: bold;
	margin-top: 10px;
}

[class*=event-details__post__] h3 a {
	color: inherit;
	-webkit-transition: 500ms;
	transition: 500ms;
}

.event-details__post__next {
	text-align: right;
	padding-left: 0;
	padding-right: 55px;
	background-image: url(./images/shapes/event-d-n-post.png);
	background-position: right center;
}


/*-------------------------------------------------------------- 
# Call to Action
--------------------------------------------------------------*/

.cta-one {
	padding-top: 117px;
	padding-bottom: 127px;
	background-repeat: no-repeat;
	background-size: cover;
	background-position: center center;
	background-color: var(--thm-black);
	box-shadow: 0px 20px 50px 0px rgba(33, 37, 43, 0.1);
}

.cta-one__btn-block {
	display: -webkit-box;
	display: flex;
	-webkit-box-pack: center;
	        justify-content: center;
	-webkit-box-align: center;
	        align-items: center;
	margin-top: 35px;
}

.cta-one h3 {
	margin: 0;
	color: #fff;
	font-size: 50px;
	margin-bottom: 20px;
}

.cta-one p {
	margin: 0;
	font-size: 18px;
	line-height: 28px;
	color: #fff;
}

.cta-one__btn-one {
	border: 2px solid rgba(255, 255, 255, 0.2);
	color: #fff;
	background-color: transparent;
	padding: 11.5px 34.5px;
	margin-right: 15px;
}

.cta-two {
	padding-bottom: 150px;
}

.cta-two .inner-container {
	margin-top: -100px;
	position: relative;
	z-index: 11;
	background-color: rgb(255, 255, 255);
	box-shadow: 0px 30px 70px 0px rgba(33, 37, 43, 0.1);
}

.cta-two__box {
	text-align: center;
	padding-top: 55px;
	padding-bottom: 55px;
}

.cta-two__icon {
	width: 90px;
	height: 90px;
	background-color: rgba(0, 0, 0, 0.05);
	display: -webkit-box;
	display: flex;
	-webkit-box-align: center;
	        align-items: center;
	-webkit-box-pack: center;
	        justify-content: center;
	text-align: center;
	margin-left: auto;
	margin-right: auto;
	margin-bottom: 20px;
	border-radius: 50%;
}

.cta-two__icon i {
	font-size: 40px;
  // color: var(--thm-black);
  color: var(--thm-base);
}

.cta-two__box h3 {
	color: var(--thm-black);
	font-size: 24px;
	margin: 0;
	margin-bottom: 15px;
}

.cta-two__box p {
	margin: 0;
	margin-bottom: 20px;
}

.cta-two__box .thm-btn {
	background-color: transparent;
  // color: var(--thm-black);
  color: var(--thm-base);
	border: 2px solid var(--thm-base);
	font-size: 16px;
	padding: 6px 26px;
}

.cta-two__box .thm-btn:hover {
	background-color: var(--thm-black);
	border-color: var(--thm-black);
	color: #fff;
}

[class*="col-"]:nth-child(2) .cta-two__box {
	background-color: var(--thm-black);
}

[class*="col-"]:nth-child(2) .cta-two__box h3 {
	color: #fff;
}

[class*="col-"]:nth-child(2) .cta-two__icon {
	background-color: rgba(255, 255, 255, 0.05);
}

[class*="col-"]:nth-child(2) .cta-two__icon i {
	color: var(--thm-base);
}

[class*="col-"]:nth-child(2) .cta-two__box .thm-btn {
	background-color: var(--thm-base);
	border-color: var(--thm-base);
	color: #fff;
}

[class*="col-"]:nth-child(2) .cta-two__box .thm-btn:hover {
	background-color: #fff;
	border-color: #fff;
	color: var(--thm-black);
}

.cta-two__home-two {
	background-color: #1b1f24;
}

.cta-two__home-two .inner-container {
	margin-top: 0;
	background-color: transparent;
}

.cta-two__home-two [class*="col-"]:nth-child(2) .cta-two__box {
	background-color: transparent;
}

.cta-two__home-two .cta-two__box h3 {
	color: #fff;
}

.cta-two__home-two [class*="col-"]:nth-child(2) .cta-two__Icon,
.cta-two__home-two .cta-two__icon {
	background-color: rgba(255, 255, 255, 0.05);
}

.cta-two__home-two .cta-two__icon i {
	color: var(--thm-base);
}

.cta-two__home-two [class*="col-"]:nth-child(2) .cta-two__box .thm-btn,
.cta-two__home-two .cta-two__box .thm-btn {
	background-color: transparent;
	color: rgba(255, 255, 255, 0.3);
	border-color: rgba(255, 255, 255, 0.1);
}

.cta-two__home-two [class*="col-"]:nth-child(2) .cta-two__box .thm-btn:hover,
.cta-two__home-two .cta-two__box .thm-btn:hover {
	background-color: var(--thm-base);
	border-color: var(--thm-base);
	color: #fff;
}

.cta-two__home-two .cta-two__box {
	display: -moz-flex;
	display: -ms-flex;
	display: -o-flex;
	display: -webkit-box;
	display: flex;
	-webkit-box-align: start;
	        align-items: flex-start;
	text-align: left;
	-webkit-box-pack: start;
	        justify-content: flex-start;
}

.cta-two__home-two .cta-two__icon {
	margin-left: 0;
	margin-right: 20px;
	width: 75px;
	height: 75px;
}

.cta-two__home-two .cta-two__icon {
	font-size: 35px;
}

.cta-two__home-two {
	padding-bottom: 0;
}

.cta-two__home-two .cta-two__box {
	padding-top: 70px;
	padding-bottom: 70px;
}

/* cta three */

.cta-three .inner-container {
	background-color: #1b1f24;
	box-shadow: 0px 20px 50px 0px rgba(33, 37, 43, 0.3);
	padding-left: 100px;
	padding-right: 100px;
	display: -webkit-box;
	display: flex;
	-webkit-box-align: start;
	        align-items: flex-start;
	background-repeat: no-repeat;
	background-position: center;
	background-size: cover;
	padding-top: 100px;
	padding-bottom: 90px;
	position: relative;
	margin-top: -190px;
	margin-bottom: -190px;
	z-index: 10;
}

.cta-three .inner-container::before {
	content: '';
	width: 60px;
	height: 2px;
	background-color: var(--thm-base);
	top: 115px;
	left: -30px;
	position: absolute;
}

.cta-three__title {
	position: relative;
	top: -10px;
}

.cta-three__title h3 {
	width: 170px;
	color: #fff;
	font-size: 30px;
	line-height: 46px;
	font-weight: bold;
	margin: 0;
}


.cta-three__title h3 span {
	color: var(--thm-base);
}

.cta-three__content {
	padding-left: 80px;
}

.cta-three__content p {
	margin: 0;
	font-size: 16px;
	line-height: 28px;
	color: #fff;
}

.cta-three__list-wrap {
	display: -webkit-box;
	display: flex;
	-webkit-box-align: center;
	        align-items: center;
	margin-top: 30px;
}

.cta-three__list {
	margin: 0;
	-webkit-box-flex: 1;
	        flex: 1 1 32%;
}

.cta-three__list:not(:first-child) {
	margin-left: 40px;
}

.cta-three__list:nth-child(1) {
	-webkit-box-flex: 1;
	        flex: 1 1 47%;
}

.cta-three__list li {
	color: rgba(255, 255, 255, .5);
	font-size: 16px;
	position: relative;
	padding-left: 23px;
}

.cta-three__list li+li {
	margin-top: 7px;
}

.cta-three__list li::before {
	content: '';
	width: 8px;
	height: 8px;
	border-radius: 50%;
	background-color: var(--thm-base);
	position: absolute;
	top: 50%;
	left: 0;
	-webkit-transform: translateY(-50%);
	        transform: translateY(-50%);
}

/*-------------------------------------------------------------- 
# Brand 
--------------------------------------------------------------*/

.brand-one {
	background-color: #181a1e;
	padding-top: 66px;
	padding-bottom: 66px;
}

.brand-one__carousel img {
	opacity: 0.6;
	-webkit-transition: all 500ms ease;
	transition: all 500ms ease;
}

.brand-one__carousel img:hover {
	opacity: 1;
}

/*-------------------------------------------------------------- 
# Main Footer 
--------------------------------------------------------------*/

.site-footer__upper {
	padding-bottom: 80px;
	padding-top: 130px;
}

.footer-widget {
	margin-bottom: 30px;
}

.footer-widget__title {
	margin: 0;
	color: #252930;
	font-size: 24px;
	font-weight: 700;
	line-height: 1em;
	margin-bottom: 30px;
}

.footer-widget p {
	margin: 0;
	font-size: 16px;
	line-height: 26px;
	color: #848484;
}

.footer-widget p+p {
	margin-top: 15px;
}

.footer-widget p a {
	color: inherit;
	-webkit-transition: all 500ms ease;
	transition: all 500ms ease;
}

.footer-widget p a:hover {
	color: var(--thm-black);
}

.footer-widget__links-list {
	margin-bottom: 0;
}

.footer-widget__links-list li {
	position: relative;
	padding-left: 20px;
}

.footer-widget__links-list li::before {
	content: "";
	width: 6px;
	height: 6px;
	border-radius: 50%;
	position: absolute;
	top: 50%;
	left: 0;
	-webkit-transform: translateY(-50%);
	        transform: translateY(-50%);
	background-color: var(--thm-base);
	-webkit-transition: all 500ms ease;
	transition: all 500ms ease;
}

.footer-widget__links-list li+li {
	margin-top: 5px;
}

.footer-widget__links-list li a {
	color: #848484;
	font-size: 16px;
	-webkit-transition: all 500ms ease;
	transition: all 500ms ease;
}

.footer-widget__links-list li:hover::before {
	color: var(--thm-black);
}

.footer-widget__links-list li:hover a {
	color: var(--thm-black);
}

.footer-widget__about {
	padding-right: 70px;
}

.footer-widget__about p {
	margin: 0;
	font-size: 20px;
	line-height: 38px;
	color: #000000;
	font-family: var(--heading-font);
}

.footer-widget__contact {
	padding-left: 60px;
}

.footer-widget__open-hrs {
	padding-left: 70px;
}

.site-footer__bottom {
	// padding-top: 30px;
}

.site-footer__bottom .inner-container {
	display: -webkit-box;
	display: flex;
	-webkit-box-align: center;
	        align-items: center;
	border-top: 1px solid #e5e5e5;
	-webkit-box-pack: justify;
	        justify-content: space-between;
}

.site-footer__bottom-logo {
	// -webkit-transform: translateY(-30px);
  //         transform: translateY(-30px);
  -webkit-transform: translateY(-50px);
	        transform: translateY(-50px);
}

.site-footer__bottom p {
	margin: 0;
}

.site-footer__bottom-links {
	display: -webkit-box;
	display: flex;
	-webkit-box-align: center;
	        align-items: center;
}

.site-footer__bottom-links a {
	color: inherit;
	-webkit-transition: all 500ms ease;
	transition: all 500ms ease;
}

.site-footer__bottom-links a:hover {
	color: var(--thm-black);
}

.site-footer__bottom-links a+a {
	margin-left: 5px;
}

/*-------------------------------------------------------------- 
# About
--------------------------------------------------------------*/

.about-one p {
	margin: 0;
	color: #848484;
	position: relative;
}

.about-one__membership {
	padding-top: 150px;
}



// .about-one__moc {
// 	position: absolute;
// 	bottom: -75px;
// 	left: -70px;
// 	-webkit-animation: ImgBounce 5s ease-in-out 0s infinite alternate;
// 	        animation: ImgBounce 5s ease-in-out 0s infinite alternate;
// }
.about-one__moc {
	position: absolute;
	bottom: 70px;
	left: 0;
	// -webkit-animation: ImgBounce 5s ease-in-out 0s infinite alternate;
	//         animation: ImgBounce 5s ease-in-out 0s infinite alternate;
}

.about-one .container {
  // padding-bottom: 175px;
  padding-bottom: 100px;
}

.about-one__membership .container {
	padding-bottom: 140px;
}

.about-one .block-title {
	margin-bottom: 50px;
}

.about-one .about-one__highlighted-text {
	font-family: var(--heading-font);
	color: #000000;
  // font-size: 24px;
  font-size: 20px;
	line-height: 44px;
	margin: 0;
	font-weight: 400;
	padding-right: 35px;
}

.about-one__feature-list {
	margin: 0;
	margin-top: 35px;
}

.col-xl-3 .about-one__feature-list {
	margin-left: -10px;
}

.about-one__feature-list li {
	position: relative;
	padding-left: 20px;
	font-size: 16px;
	word-break: break-all;
	color: #252930;
}

.about-one__feature-list li+li {
	margin-top: 10px;
}

.about-one__feature-list li::before {
	content: "";
	width: 8px;
	height: 8px;
	border-radius: 50%;
	background-color: var(--thm-base);
	position: absolute;
	top: 50%;
	left: 0;
	-webkit-transform: translateY(-50%);
	        transform: translateY(-50%);
}

.about-one__feature h3 {
	margin: 0;
	color: var(--thm-black);
	font-size: 30px;
	font-weight: bold;
	font-weight: 700;
	margin-top: 55px;
	margin-bottom: 15px;
}

.about-cta__wrapper {
	position: relative;
	background-image: -webkit-gradient(linear,
			left bottom, left top,
			from(rgba(245, 247, 250, 0)),
			to(rgba(245, 247, 250, 0.8)));
	background-image: linear-gradient(0deg,
			rgba(245, 247, 250, 0) 0%,
			rgba(245, 247, 250, 0.8) 100%);
}

.about-cta__wrapper .container {
	position: relative;
}

.about-cta__wrapper::before {
	content: "";
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	opacity: 0.3;
	background-position: center center;
	background-repeat: no-repeat;
	background-size: cover;
  // background-image: url(./images/backgrounds/event-bg-1-1.jpg);
  background-image: url('./images/backgrounds/event-bg-1-1.jpg');
  background-color: #EFF0F2;
  background-blend-mode: multiply;
}

.about-two {
	padding-top: 150px;
	padding-bottom: 150px;
	background-repeat: no-repeat;
	background-position: bottom center;
	position: relative;
}

.about-two::before {
	content: '';
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background-color: #f5f7fa;
	opacity: 70%;
}

.about-two__sclupture {
	position: absolute;
	bottom: -100px;
	left: 50px;
	z-index: 10;
	-webkit-animation: ImgBounce 5s ease-in-out 0s infinite alternate;
	        animation: ImgBounce 5s ease-in-out 0s infinite alternate;
}

@media(max-width: 1440px) {
	.about-two__sclupture {
		left: -10%;
	}
}

.about-two .container {
	position: relative;
}

.about-two__content p {
	color: #252930;
	font-size: 16px;
	line-height: 28px;
	margin: 0;
}

.about-two__content .about-two__highlight {
	font-family: var(--heading-font);
	color: #000000;
	font-size: 24px;
	line-height: 44px;
	margin: 0;
}

.about-two__content .block-title {
	margin-bottom: 50px;
}

.about-two__content .about-two__highlight {
	margin-bottom: 30px;
}

.about-two__image {
	margin-top: 50px;
	position: relative;
	left: 100px;
}

.about-two__image::before {
	content: '';
	position: absolute;
	width: 250px;
	height: 250px;
	background-image: url(./images/shapes/about-dot-2.png);
	background-repeat: no-repeat;
	right: -70px;
	top: -70px;
}

.about-two__image>img {
	box-shadow: 0px 20px 50px 0px rgba(33, 37, 43, 0.3);
	z-index: 10;
	position: relative;
}

/* about three */

.about-three {
	background-image: -webkit-gradient(linear, left top, right top, from(rgba(245, 247, 250, 0)), to(rgba(245, 247, 250, 0.8)));
	background-image: linear-gradient(90deg, rgba(245, 247, 250, 0) 0%, rgba(245, 247, 250, 0.8) 100%);
}

.about-three .container-fluid {
	padding-left: 0;
	padding-right: 0;
}

.about-three .my-auto {
	display: block;
	width: 100%;
}

.about-three__image img {
	float: right;
}

.about-three__content {
	width: 100%;
	max-width: 520px;
	margin-left: auto;
	margin-right: auto;
}

.about-three__content>p {
	font-size: 16px;
	line-height: 28px;
	color: #252930;
	margin: 0;
}

.about-three__content .about-three__highlight {
	font-family: var(--heading-font);
	color: #000000;
	font-size: 24px;
	line-height: 44px;
}

.about-three__content .block-title {
	margin-bottom: 50px;
}

.about-three__content .about-three__highlight {
	margin-bottom: 25px;
}


/* about four */

.about-four {
	padding-top: 150px;
	padding-bottom: 140px;
}


.about-four {
	position: relative;
	background-image: -webkit-gradient(linear, left top, right top, from(rgba(245, 247, 250, 0)), to(rgba(245, 247, 250, 0.8)));
	background-image: linear-gradient(90deg, rgba(245, 247, 250, 0) 0%, rgba(245, 247, 250, 0.8) 100%);
}

.about-four .container {
	position: relative;
}

.about-four::before {
	content: "";
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	opacity: 0.3;
	background-position: center center;
	background-repeat: no-repeat;
	background-size: cover;
	background-image: url(./images/backgrounds/event-bg-1-1.jpg);
}

.about-four__content>p {
	font-size: 16px;
	line-height: 28px;
	color: #252930;
	margin: 0;
}

.about-four__content>p+p {
	margin-top: 30px;

}

.about-four__highlite-text {
	margin-bottom: 30px;
}

.about-four__highlite-text p {
	margin: 0;
	color: #000000;
	font-size: 24px;
	line-height: 44px;
	font-family: var(--heading-font);
}

.about-four__image {
	position: absolute;
	right: 0;
	top: 150px;
}

.about-four__image>img {
	position: relative;
	box-shadow: 0px 20px 50px 0px rgba(33, 37, 43, 0.3);
}

.about-four__image::before {
	content: '';
	width: 250px;
	height: 370px;
	background-image: url(./images/shapes/about-4-dots.png);
	background-repeat: no-repeat;
	background-position: top left;
	position: absolute;
	top: -100px;
	right: -70px;
}

.about-four__content .block-title {
	margin-bottom: 50px;
}


/*-------------------------------------------------------------- 
 # SideMenu 
 --------------------------------------------------------------*/

.side-menu__block {
	position: fixed;
	top: 0;
	right: 0;
	width: 100vw;
	height: 100vh;
	z-index: 1000;
	-webkit-transform: scale(0, 1);
	transform: scale(0, 1);
	-webkit-transform-origin: left center;
	transform-origin: right center;
	-webkit-transition: -webkit-transform 0.7s ease;
	transition: -webkit-transform 0.7s ease;
	transition: transform 0.7s ease;
	transition: transform 0.7s ease, -webkit-transform 0.7s ease;
}

.side-menu__block.active {
	-webkit-transform: scale(1, 1);
	transform: scale(1, 1);
}

.side-menu__block-overlay {
	width: 100%;
	height: 100%;
	background-color: #000;
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	z-index: 999;
	opacity: 0.7;
}

.side-menu__block-inner {
	position: relative;
	width: 300px;
	margin-left: auto;
	height: 100vh;
	background-color: var(--thm-black);
	z-index: 999999;
	overflow-y: auto;
	padding: 40px 0;
	padding-top: 60px;
	position: relative;
	display: -webkit-box;
	display: flex;
	-webkit-box-pack: center;
	justify-content: center;
	align-content: space-between;
}

.side-menu__block-inner .mCustomScrollBox {
	width: 100%;
}

.side-menu__close-btn {
	position: absolute;
	top: 20px;
	right: 30px;
	z-index: 9999999;
	font-size: 18px;
	color: #fff;
	opacity: 1;
	-webkit-transition: all 500ms ease;
	transition: all 500ms ease;
}

.side-menu__close-btn:hover {
	color: var(--thm-base);
}

.mobile-nav__container {
	margin: 40px 0;
	border-top: 1px solid rgba(var(--thm-base-rgb), 0.3);
	border-bottom: 1px solid rgba(var(--thm-base-rgb), 0.3);
}

.mobile-nav__container ul {
	margin: 0;
	padding: 0;
	list-style: none;
}

.mobile-nav__container li.dropdown ul {
	display: none;
}

.mobile-nav__container li.dropdown {
	position: relative;
}

.mobile-nav__container li.dropdown .dropdown-btn {
	border: none;
	outline: none;
	width: 35px;
	height: 35px;
	background-color: #000000;
	display: -webkit-box;
	display: flex;
	-webkit-box-align: center;
	align-items: center;
	-webkit-box-pack: center;
	justify-content: center;
	text-align: center;
	font-size: 20px;
	color: #fff;
	position: absolute;
	top: 10px;
	right: 0;
	-webkit-transform: rotate(0);
	transform: rotate(0);
	-webkit-transition: transfrom 500ms ease, color 500ms ease,
		background-color 500ms ease;
	transition: transfrom 500ms ease, color 500ms ease,
		background-color 500ms ease;
}

.mobile-nav__container li.dropdown .dropdown-btn.open {
	-webkit-transform: rotate(90deg);
	transform: rotate(90deg);
	background: #ffffff;
	color: var(--thm-black);
}

.mobile-nav__container li+li {
	border-top: 1px solid rgba(var(--thm-base-rgb), 0.3);
}

.mobile-nav__container li>ul {
	border-top: 1px solid rgba(var(--thm-base-rgb), 0.3);
}

.mobile-nav__container li a {
	text-transform: uppercase;
	color: #fff;
	font-size: 16px;
	font-weight: 600;
	display: block;
	font-family: var(--thm-font);
	padding: 10px 0;
	padding-left: 30px;
	padding-right: 30px;
	border-left: 3px solid transparent;
	-webkit-transition: all 500ms ease;
	transition: all 500ms ease;
	position: relative;
}

.mobile-nav__container li a:hover {
	background-color: var(--thm-base);
	color: #ffffff;
}

.mobile-nav__container li.current-menu-item>a,
.mobile-nav__container li.current>a {
	border-color: var(--thm-primary);
	background-color: var(--thm-base);
}

.mobile-nav__container .navigation-box__right {
	border-top: 1px solid rgba(var(--thm-base-rgb), 0.3);
}

.side-menu__logo {
	padding-left: 30px;
}

.side-menu__block__copy {
	color: #cccccc;
	font-family: var(--thm-font);
	font-size: 18px;
	text-align: center;
	padding-left: 30px;
	padding-right: 30px;
}

.side-menu__block__copy a {
	text-transform: uppercase;
	color: #fff;
	font-weight: bold;
	-webkit-transition: all 500ms ease;
	transition: all 500ms ease;
}

.side-menu__block__copy a:hover {
	color: var(--thm-base);
}

.side-menu__social {
	display: -moz-flex;
	display: -ms-flex;
	display: -o-flex;
	display: -webkit-box;
	display: flex;
	-webkit-box-align: center;
	align-items: center;
	-webkit-box-pack: center;
	justify-content: center;
}

.side-menu__social a {
	color: #ffffff;
	font-size: 16px;
	-webkit-transition: all 500ms ease;
	transition: all 500ms ease;
}

.side-menu__social a:hover {
	color: var(--thm-base);
}

.side-menu__social a+a {
	margin-left: 19px;
}

/* side content */

.side-content__block {
	position: fixed;
	top: 0;
	left: 0;
	width: 100vw;
	height: 100vh;
	z-index: 1000;
	-webkit-transform: scale(0, 1);
	transform: scale(0, 1);
	-webkit-transform-origin: left center;
	transform-origin: left center;
	-webkit-transition: -webkit-transform 0.7s ease;
	transition: -webkit-transform 0.7s ease;
	transition: transform 0.7s ease;
	transition: transform 0.7s ease, -webkit-transform 0.7s ease;
}

.side-content__block.active {
	-webkit-transform: scale(1, 1);
	transform: scale(1, 1);
}

.side-content__block-overlay {
	width: 100%;
	height: 100%;
	background-color: #000;
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	z-index: 999;
	opacity: 0.7;
}

.side-content__block-inner {
	position: relative;
	width: 300px;
	height: 100vh;
	background-color: #fff;
	z-index: 999999;
	overflow-y: auto;
	padding: 20px 15px;
	position: relative;
	display: -webkit-box;
	display: flex;
	-webkit-box-pack: center;
	justify-content: center;
	align-content: space-between;
}

.side-content__block-inner .mCustomScrollBox {
	width: 100%;
}

.side-content__block .main-navigation .submenu {
	display: none;
}

.side-content__block__text {
	text-align: center;
}

.side-content__block__title {
	font-size: 20px;
	color: var(--thm-black);
	margin: 0;
	margin-bottom: 15px;
}

.side-content__block-line {
	margin: 40px 0;
	border-color: rgba(48, 46, 47, 0.2);
}

.side-content__block-about {
	margin-top: 40px;
}

.side-content__block-about__btn:hover {
	background-color: var(--thm-black);
	color: #fff;
}

.side-content__block-contact__list {
	margin: 0;
	padding: 0;
	list-style: none;
	margin-bottom: 30px;
}

.side-content__block-contact__list-item {
	color: var(--thm-black);
	font-size: 15px;
	position: relative;
	padding-left: 30px;
}

.side-content__block-contact__list-item a {
	color: inherit;
	-webkit-transition: all 0.4s ease;
	transition: all 0.4s ease;
}

.side-content__block-contact__list-item a:hover {
	color: var(--thm-black);
}

.side-content__block-contact__list-item i {
	font-size: 17px;
	color: var(--thm-black);
	position: absolute;
	top: 50%;
	left: 0;
	-webkit-transform: translateY(-50%);
	transform: translateY(-50%);
}

.side-content__block-contact__list-item+.side-content__block-contact__list-item {
	margin-top: 10px;
}

.side-content__block__text-copy {
	position: absolute;
	bottom: 0;
	left: 50%;
	-webkit-transform: translateX(-50%);
	transform: translateX(-50%);
}

.side-content__block__text-copy a {
	color: inherit;
	-webkit-transition: all 0.4s ease;
	transition: all 0.4s ease;
}

.side-content__block__text-copy a:hover {
	color: var(--thm-black);
}

.side-content__block__text a {
	color: var(--thm-black);
	-webkit-transition: all 0.4s ease;
	transition: all 0.4s ease;
}

.side-content__block__text a:hover {
	color: var(--thm-black);
}

/*-------------------------------------------------------------- 
 # Search Popup 
 --------------------------------------------------------------*/

.search-popup {
	position: fixed;
	top: 0;
	left: 0;
	width: 100vw;
	height: 100vh;
	z-index: 1000;
	-webkit-transform: scale(1, 0);
	transform: scale(1, 0);
	-webkit-transform-origin: bottom center;
	transform-origin: bottom center;
	-webkit-transition: -webkit-transform 0.7s ease;
	transition: -webkit-transform 0.7s ease;
	transition: transform 0.7s ease;
	transition: transform 0.7s ease, -webkit-transform 0.7s ease;
}

.search-popup.active {
	-webkit-transform-origin: top center;
	transform-origin: top center;
	-webkit-transform: scale(1, 1);
	transform: scale(1, 1);
}

.search-popup__overlay {
	width: 100%;
	height: 100%;
	background-color: #000;
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	z-index: 999;
	opacity: 0.7;
	cursor: none;
}

.search-popup__inner {
	position: absolute;
	width: 100%;
	height: 100%;
	display: -webkit-box;
	display: flex;
	-webkit-box-pack: center;
	justify-content: center;
	-webkit-box-align: center;
	align-items: center;
}

.search-popup__form {
	position: relative;
	z-index: 9991;
	width: 100%;
	padding: 15px;
	max-width: 600px;
	position: relative;
}

.search-popup__form input {
	background-color: transparent;
	border: none;
	outline: none;
	width: 100%;
	height: 60px;
	color: var(--thm-black);
	font-size: 18px;
	background-color: white;
	padding-left: 30px;
}

.search-popup__form ::-webkit-input-placeholder {
	opacity: 1;
	color: var(--thm-black);
}

.search-popup__form ::-moz-placeholder {
	opacity: 1;
	color: var(--thm-black);
}

.search-popup__form :-ms-input-placeholder {
	opacity: 1;
	color: var(--thm-black);
}

.search-popup__form ::-ms-input-placeholder {
	opacity: 1;
	color: var(--thm-black);
}

.search-popup__form ::placeholder {
	opacity: 1;
	color: var(--thm-black);
}

.search-popup__form input::-webkit-input-placeholder {
	/* Chrome/Opera/Safari */
	color: var(--thm-black);
}

.search-popup__form input::-moz-placeholder {
	/* Firefox 19+ */
	color: var(--thm-black);
}

.search-popup__form input:-ms-input-placeholder {
	/* IE 10+ */
	color: var(--thm-black);
}

.search-popup__form input:-moz-placeholder {
	/* Firefox 18- */
	color: var(--thm-black);
}

.search-popup__form button[type="submit"] {
	border: none;
	outline: none;
	width: 60px;
	height: 60px;
	color: #fff;
	background-color: var(--thm-black);
	cursor: pointer;
	position: absolute;
	top: 50%;
	right: 15px;
	-webkit-transform: translateY(-50%);
	transform: translateY(-50%);
	-webkit-transition: all 0.4s ease;
	transition: all 0.4s ease;
}

.search-popup__form button[type="submit"]:hover {
	background-color: var(--thm-base);
	color: #fff;
}


/*--------------------------------------------------------------
# Testimonials
--------------------------------------------------------------*/

.testimonials-one {
	background-color: #f5f7fa;
}

.testimonials-one .container-fluid {
	padding-left: 0;
	padding-right: 0;
}

.testimonials-one .block-title {
	margin-bottom: 60px;
}

.testimonials-one__block {
	margin-left: auto;
	margin-right: auto;
	width: 100%;
	max-width: 520px;
}

.testimonials-one__carousel .owl-item img {
	width: auto;
}

.testimonials-one .my-auto {
	width: 100%;
	display: block;
}

.testimonials-one__author {
	display: -webkit-box;
	display: flex;
	-webkit-box-align: center;
	        align-items: center;
}

.testimonials-one__author img {
	border-radius: 50%;
}

.testimonials-one__author h4 {
	margin: 0;
	font-size: 16px;
	color: var(--thm-black);
	font-family: var(--thm-font);
	text-transform: uppercase;
	margin-left: 15px;
}

.testimonials-one__single>p {
	margin: 0;
	font-family: var(--heading-font);
	font-size: 22px;
	color: #000000;
	line-height: 40px;
	font-style: italic;
	margin-top: 30px;
	margin-bottom: 15px;
}

.testimonials-one__carousel.owl-theme .owl-nav {
	display: -webkit-box;
	display: flex;
	-webkit-box-align: start;
	        align-items: flex-start;
	-webkit-box-pack: start;
	        justify-content: flex-start;
}

.testimonials-one__carousel.owl-theme .owl-nav {
	margin-top: 45px;
}

.testimonials-one__carousel.owl-theme .owl-nav [class*=owl-] {
	width: 60px;
	height: 45px;
	border: 1px solid var(--thm-base);
	opacity: 50%;
	display: -webkit-box;
	display: flex;
	-webkit-box-align: center;
	        align-items: center;
	-webkit-box-pack: center;
	        justify-content: center;
	-webkit-transition: 500ms;
	transition: 500ms;
	position: relative;
	outline: none;
	margin: 0;
	padding: 0;
}

.testimonials-one__carousel.owl-theme .owl-nav [class*=owl-]:hover {
	border-color: var(--thm-base);
	background-color: transparent;
	opacity: 100%;
}


.testimonials-one__carousel.owl-theme .owl-nav [class*=owl-]+[class*=owl-] {
	margin-left: 10px;
}

.testimonials-one__carousel.owl-theme .owl-nav [class*=owl-] span {
	color: rgba(0, 0, 0, 0);
	display: -webkit-box;
	display: flex;
	-webkit-box-align: center;
	        align-items: center;
	-webkit-box-pack: center;
	        justify-content: center;
	position: relative;
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	text-align: center;
}


.testimonials-one__carousel.owl-theme .owl-nav [class*=owl-] span::before {
	content: '\f30a';
	color: var(--thm-base);
	font-size: 17px;
	font-family: "Font Awesome 5 Free";
	font-weight: 900;
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	text-align: center;
	display: -webkit-box;
	display: flex;
	-webkit-box-align: center;
	        align-items: center;
	-webkit-box-pack: center;
	        justify-content: center;
}

.testimonials-one__carousel.owl-theme .owl-nav [class*=owl-]:nth-child(2) span::before {
	content: '\f30b';
}


/*--------------------------------------------------------------
# Page Header
--------------------------------------------------------------*/

.page-header {
	background-repeat: no-repeat;
	background-position: top center;
	background-attachment: fixed;
	position: relative;
	padding-top: 120px;
	padding-bottom: 120px;
}


.page-header::before {
	content: '';
	position: absolute;
	top: 0;
	left: 0;
	bottom: 0;
	right: 0;
	background-color: #000000;
	opacity: 60%;
}

.page-header .container {
	position: relative;
	text-align: center;
}

.page-header h2 {
	color: #fff;
	font-size: 50px;
	font-weight: bold;
	margin: 0;
}



/*--------------------------------------------------------------
# Team
--------------------------------------------------------------*/

.team-one {
	background-color: #f5f7fa;
	padding-bottom: 95px;
}

@media(min-width: 1200px) {
	.team-one .container>.row {
		margin-left: -53px;
		margin-right: -53px;
	}

	.team-one .container>.row>[class*=col-] {
		padding-left: 53px;
		padding-right: 53px;
	}
}

.team-one .block-title-two {
	padding-top: 150px;
}

.team-one__single {
	margin-bottom: 65px;
}

.team-one__image {
	background-color: #000000;
	position: relative;
	overflow: hidden;
}

.team-one__image>img {
	width: 100%;
	opacity: 1;
	-webkit-transition: all 500ms ease;
	transition: all 500ms ease;
}

.team-one__single:hover .team-one__image>img {
	opacity: 0.7;
}

.team-one__social {
	position: absolute;
	width: 100%;
	background-color: var(--thm-base);
	text-align: center;
	display: -webkit-box;
	display: flex;
	-webkit-box-align: center;
	        align-items: center;
	-webkit-box-pack: center;
	        justify-content: center;
	bottom: 0;
	left: 0;
	padding-top: 25px;
	padding-bottom: 25px;
	-webkit-transform: translateY(50%);
	        transform: translateY(50%);
	opacity: 0;
	visibility: hidden;
	-webkit-transition: opacity 500ms ease, visibility 500ms ease, -webkit-transform 500ms ease;
	transition: opacity 500ms ease, visibility 500ms ease, -webkit-transform 500ms ease;
	transition: transform 500ms ease, opacity 500ms ease, visibility 500ms ease;
	transition: transform 500ms ease, opacity 500ms ease, visibility 500ms ease, -webkit-transform 500ms ease;
}

.team-one__single:hover .team-one__social {
	-webkit-transform: translateY(0%);
	        transform: translateY(0%);
	opacity: 1;
	visibility: visible;
}

.team-one__social a {
	display: -webkit-box;
	display: flex;
	width: 40px;
	height: 40px;
	-webkit-box-align: center;
	        align-items: center;
	-webkit-box-pack: center;
	        justify-content: center;
	text-align: center;
	border: 1px solid rgba(255, 255, 255, .3);
	color: #fff;
	-webkit-transition: 500ms;
	transition: 500ms;
	font-size: 14px;
	border-radius: 50%;
}

.team-one__social a:hover {
	color: var(--thm-black);
	border-color: #ffff;
	background-color: #fff;
}

.team-one__social a+a {
	margin-left: 10px;
}

.team-one__content {
	text-align: center;
}

.team-one__content h3 {
	color: var(--thm-black);
	margin: 0;
	font-size: 24px;
	font-weight: bold;
	margin-top: 25px;
	margin-bottom: 10px;
}

.team-one__content p {
	margin: 0;
	color: var(--thm-base);
	text-transform: uppercase;
	font-size: 16px;
}

/*--------------------------------------------------------------
# Video
--------------------------------------------------------------*/

.video-one {
	background-repeat: no-repeat;
	background-position: center center;
	background-attachment: fixed;
	background-size: cover;

	position: relative;

}

.video-one::before {
	content: '';
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background-color: #000000;
	opacity: 0.3;
}

.video-one .container {
	position: relative;
	padding-top: 320px;
	padding-bottom: 320px;
}

.video-one__btn {
	width: 110px;
	height: 110px;
	margin-left: auto;
	margin-right: auto;
	border: 4px solid #fff;
	border-radius: 50%;
	font-size: 40px;
	display: -webkit-box;
	display: flex;
	-webkit-box-align: center;
	        align-items: center;
	-webkit-box-pack: center;
	        justify-content: center;
	text-align: center;
	color: #fff;
	-webkit-transition: 500ms;
	transition: 500ms;
}

.video-one__btn:hover {
	background-color: #fff;
	color: var(--thm-black);
}



/*--------------------------------------------------------------
# History
--------------------------------------------------------------*/

.history-one {
	padding-bottom: 150px;
	padding-top: 95px;
	position: relative;
}

.history-one__sculp {
	position: absolute;
	top: -95px;
	left: 3%;
	-webkit-animation: ImgBounce 5s ease-in-out 0s infinite alternate;
	        animation: ImgBounce 5s ease-in-out 0s infinite alternate;
}

@media(max-width: 575px) {
	.history-one__sculp {
		display: none;
	}
}

.history-one__single:nth-child(odd) .row {
	-webkit-box-orient: horizontal;
	-webkit-box-direction: reverse;
	        flex-direction: row-reverse;
}

.history-one__single:nth-child(odd) .history-one__image {
	display: -webkit-box;
	display: flex;
	-webkit-box-pack: end;
	        justify-content: flex-end;
}

.history-one__image>img {
	box-shadow: 0px 20px 50px 0px rgba(33, 37, 43, 0.2);
	position: relative;
}

.history-one__image {
	position: relative;
}

.history-one__image::before {
	content: '';
	position: absolute;
	width: 250px;
	height: 202px;
	background-image: url(./images/shapes/about-dot-2.png);
	background-repeat: no-repeat;
	top: 50%;
	left: 0;
	-webkit-transform: translateY(-50%) translateX(-50%);
	        transform: translateY(-50%) translateX(-50%);
}

.history-one__content {
	width: 100%;
	padding-right: 70px;
}

.history-one__single:nth-child(even) .history-one__content {
	padding-right: 0;
	padding-left: 70px;
}


.history-one__single {
	position: relative;
}

.history-one__single::before {
	content: '';
	width: 25px;
	height: 25px;
	border: 7px solid #f0ede6;
	background-color: var(--thm-base);
	position: absolute;
	top: 50%;
	left: 50%;
	-webkit-transform: translateY(-50%) translateX(-50%);
	        transform: translateY(-50%) translateX(-50%);
	border-radius: 50%;
	z-index: 11;
}

.history-one__single:nth-child(odd) .history-one__image::before {
	left: auto;
	right: 0;
	-webkit-transform: translateY(-50%) translateX(50%);
	        transform: translateY(-50%) translateX(50%);
}

.history-one__single::after {
	content: '';
	width: 1px;
	height: 100%;
	background-color: #e5e5e5;
	position: absolute;
	top: 0;
	left: 50%;
	-webkit-transform: translateX(-50%);
	        transform: translateX(-50%);
}

.history-one__single+.history-one__single {
	padding-top: 70px;
}

.history-one__top p {
	font-size: 30px;
	font-weight: 400;
	letter-spacing: 0;
	margin-bottom: 0;
}

.history-one__top h3 {
	font-size: 26px;
	margin-top: 15px;
	margin-bottom: 40px;
}


/*--------------------------------------------------------------
# Contact Map
--------------------------------------------------------------*/

.contact-map-one .google-map__contact {
	display: block;
	border: none;
	outline: none;
	width: 100%;
	height: 500px;
	box-shadow: 0px -5px 70px 0px rgba(33, 37, 43, 0.2);
}

.contact-map-one {
	padding-top: 150px;
	padding-bottom: 150px;
}


/*--------------------------------------------------------------
# Contact Box
--------------------------------------------------------------*/

.contact-box-one {
	padding-bottom: 140px;
}

.contact-box-one .row.high-gutters {
	margin-left: -20px;
	margin-right: -20px;
}

.contact-box-one .row.high-gutters>[class*=col-] {
	padding-left: 20px;
	padding-right: 20px;
}

.contact-box-one__single h3 {
	margin: 0;
	color: var(--thm-black);
	font-size: 24px;
	font-weight: bold;
	margin-bottom: 25px;
}

.contact-box-one__single p {
	margin: 0;
	color: #848484;
	line-height: 28px;
	font-size: 16px;
}


/*--------------------------------------------------------------
# Contact Form
--------------------------------------------------------------*/

.contact-one__box+.contact-one__box {
	margin-top: 40px;
}

.contact-one__box h3 {
	font-size: 20px;
	font-weight: bold;
	margin: 0;
	color: var(--thm-black);
	margin-bottom: 20px;
}

.contact-one__box p {
	margin: 0;
	font-size: 16px;
	line-height: 28px;
	color: #848484;
}

.contact-one__box p+p {
	margin-top: 15px;
}

.contact-one__box p a {
	color: inherit;
	-webkit-transition: all 500ms ease;
	transition: all 500ms ease;
}

.contact-one__box p a:hover {
	color: var(--thm-black);
}

.contact-one__box-social {
	display: -webkit-box;
	display: flex;
	-webkit-box-align: center;
	        align-items: center;
}

.contact-one__box-social a {
	display: -webkit-box;
	display: flex;
	-webkit-box-align: center;
	        align-items: center;
	-webkit-box-pack: center;
	        justify-content: center;
	width: 45px;
	height: 45px;
	color: #848484;
	font-size: 14px;
	background-color: rgba(0, 0, 0, .1);
	-webkit-transition: 500ms;
	transition: 500ms;
}

.contact-one__box-social a:hover {
	background-color: var(--thm-base);
	color: #fff;
}


.contact-one__box-social a+a {
	margin-left: 10px;
}

.contact-one__form textarea,
.contact-one__form input:not([type="checkbox"]):not([type="radio"]):not([type="submit"]) {
	width: 100%;
	border: none;
	height: 60px;
	border: 1px solid #e5e5e5;
	color: #848484;
	outline: none;
	padding-left: 20px;
	margin-bottom: 30px;
}

.contact-one__form ::-webkit-input-placeholder {
	opacity: 1;
	color: #848484;
}

.contact-one__form ::-moz-placeholder {
	opacity: 1;
	color: #848484;
}

.contact-one__form :-ms-input-placeholder {
	opacity: 1;
	color: #848484;
}

.contact-one__form ::-ms-input-placeholder {
	opacity: 1;
	color: #848484;
}

.contact-one__form ::placeholder {
	opacity: 1;
	color: #848484;
}

.contact-one__form textarea {
	height: 165px;
	padding-top: 15px;
}

.contact-one__btn:hover {
	background-color: var(--thm-black);
	color: #fff;
}

.contact-one__form .bootstrap-select:not([class*=col-]):not([class*=form-control]):not(.input-group-btn) {
	width: 100%;
}

.contact-one__form .bootstrap-select>.dropdown-toggle {
	height: 60px;
	border-radius: 0;
	border: 1px solid #e5e5e5;
	display: -webkit-box;
	display: flex;
	-webkit-box-align: center;
	        align-items: center;
	padding-left: 20px;
	background-color: transparent;
}

.contact-one__form .bootstrap-select .dropdown-toggle .filter-option {
	display: -webkit-box;
	display: flex;
	-webkit-box-align: center;
	        align-items: center;
}

.contact-one__form .dropdown-toggle::after {
	position: absolute;
	top: 50%;
	right: 20px;
	-webkit-transform: translateY(-3px);
	        transform: translateY(-3px);
}


/*--------------------------------------------------------------
# Venue
--------------------------------------------------------------*/

.venue-one {
	padding-top: 150px;
	padding-bottom: 110px;
}

.venue-one__top {
	margin-bottom: 60px;
}

.venue-one__top>p {
	margin: 0;
	margin-top: 55px;
}

.venue-one .row.high-gutter {
	margin-left: -52px;
	margin-right: -52px;
}

.venue-one .row.high-gutter>[class*=col-] {
	padding-left: 52px;
	padding-right: 52px;
}

.venue-one__single {
	text-align: center;
	margin-bottom: 40px;
}

.venue-one__single h3 {
	color: var(--thm-black);
	font-size: 24px;
	font-weight: bold;
	margin: 0;
	margin-top: 30px;
	margin-bottom: 30px;
}

.venue-one__single h3 a {
	color: inherit;
	-webkit-transition: 500ms;
	transition: 500ms;
}

.venue-one__single h3 a:hover {
	color: var(--thm-base);
}

.venue-one__single p {
	margin: 0;
	color: #848484;
	font-size: 16px;
	line-height: 1em;
}

.venue-one__single span {
	display: block;
	line-height: 1em;
}

.venue-one__time {
	text-transform: uppercase;
	color: var(--thm-base);
	font-size: 16px;
	line-height: 1em;
	display: block;
	margin-bottom: 10px;
}

.venue-one__address i {
	margin-right: 10px;
}

.venue-one__btn {
	border: 1px solid #e5e5e5;
	font-size: 16px;
	background-color: transparent;
	color: #252930;
	margin-top: 25px;
}

.venue-one__btn:hover {
	background-color: var(--thm-base);
	color: #fff;
}

.venue-one__image {
	overflow: hidden;
	position: relative;
}

.venue-one__image::after {
	content: "";
	width: 150%;
	height: 200%;
	position: absolute;
	top: -100%;
	left: -180%;
	z-index: 11;
	-webkit-transition: 1s;
	transition: 1s;
	-webkit-transform: rotate(30deg);
	        transform: rotate(30deg);
	background: -webkit-gradient(linear,
			left top, right top,
			color-stop(50%, rgba(255, 255, 255, 0)),
			to(rgba(255, 255, 255, 0.7)));
	background: linear-gradient(90deg,
			rgba(255, 255, 255, 0) 50%,
			rgba(255, 255, 255, 0.7) 100%);
}

.venue-one__single:hover .venue-one__image::after {
	top: 100%;
	left: 100%;
	-webkit-transform: rotate(0);
	        transform: rotate(0);
}

.venue-one__image>img {
	width: 100%;
	box-shadow: 0px 20px 50px 0px rgba(33, 37, 43, 0.1);
}

/*--------------------------------------------------------------
# Membership
--------------------------------------------------------------*/

.membership-one {
	background-color: #f5f7fa;
	padding-top: 150px;
	padding-bottom: 110px;
}

.membership-one__single {
	padding-left: 50px;
	padding-right: 50px;
	margin-bottom: 40px;
	text-align: center;
	box-shadow: 0px 20px 50px 0px rgba(33, 37, 43, 0.1);
	background-color: #fff;
	padding-top: 50px;
	padding-bottom: 50px;
}

.membership-one__single h3 {
	color: var(--thm-black);
	font-size: 24px;
	font-weight: bold;
	margin: 0;
	margin-bottom: 5px;
}

.membership-one__single p {
	margin: 0;
	font-size: 16px;
	line-height: 26px;
	color: #848484;
	margin-top: 30px;
	margin-bottom: 25px;
}

.membership-one__btn {
	padding: 6px 26.5px;
	border: 2px solid #e5e5e5;
	font-size: 16px;
	font-weight: bold;
	color: var(--thm-black);
	background-color: #fff;
}


.membership-one__btn:hover {
	background-color: var(--thm-base);
	border-color: var(--thm-base);
	color: #fff;
}

.membership-one__price {
	margin-bottom: 35px;
	border-radius: 50%;
	background-color: rgb(255, 255, 255);
	box-shadow: 0px 10px 20px 0px rgba(33, 37, 43, 0.1);
	font-size: 14px;
	line-height: 1em;
	color: #848484;
	width: 100px;
	height: 100px;
	display: -webkit-box;
	display: flex;
	-webkit-box-align: center;
	        align-items: center;
	-webkit-box-pack: center;
	        justify-content: center;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	        flex-direction: column;
	margin-left: auto;
	margin-right: auto;
}

.membership-one__price span {
	display: block;
	color: var(--thm-base);
	font-size: 30px;
	font-weight: bold;
	line-height: 1em;
	margin-bottom: 10px;
}

.membership-one__price {
	position: relative;
}

.membership-one__price::before {
	content: "";
	width: 180px;
	height: 10px;
	border-top: 2px solid var(--thm-base);
	border-bottom: 2px solid var(--thm-base);
	opacity: 0.5;
	position: absolute;
	top: 50%;
	left: 50%;
	-webkit-transform: translate(-50%, -50%) rotate(-45deg) skewX(45deg);
	        transform: translate(-50%, -50%) rotate(-45deg) skewX(45deg);
	-webkit-transition: -webkit-transform 500ms ease;
	transition: -webkit-transform 500ms ease;
	transition: transform 500ms ease;
	transition: transform 500ms ease, -webkit-transform 500ms ease;
}

.membership-one__single:hover .membership-one__price::before {
	-webkit-transform: translate(-50%, -50%) rotate(45deg) skewX(45deg);
	        transform: translate(-50%, -50%) rotate(45deg) skewX(45deg);
}

.membership-one__price-inner {
	position: relative;
	background-color: #fff;
	z-index: 10;
	width: 100%;
	height: 100%;
	display: -webkit-box;
	display: flex;
	-webkit-box-align: center;
	        align-items: center;
	-webkit-box-pack: center;
	        justify-content: center;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	        flex-direction: column;
	border-radius: 50%;
}

/*--------------------------------------------------------------
# FAQ
--------------------------------------------------------------*/

.faq-one {
	padding-top: 150px;
	padding-bottom: 110px;
}

.faq-one__top {
	margin-bottom: 60px;
}

.faq-one__top>p {
	margin: 0;
	font-size: 16px;
	line-height: 28px;
	color: #848484;
	margin-top: 55px;
}

.faq-one .accrodion-grp {
	margin-bottom: 40px;
}

.faq-one .accrodion {
	border: 1px solid #e5e5e5;
}

.faq-one .accrodion+.accrodion {
	margin-top: 20px;
}

.faq-one .accrodion-title {
	padding-top: 18px;
	padding-bottom: 18px;
	padding-left: 30px;
	cursor: pointer;
	padding-right: 30px;
}

.faq-one .accrodion-title h4 {
	font-family: var(--thm-font);
	color: #252930;
	font-size: 20px;
	margin: 0;
}

.faq-one .accrodion-content {
	padding-left: 30px;
	padding-right: 30px;
	border-top: 1px solid #e5e5e5;
	padding-top: 25px;
	padding-bottom: 25px;
}

.faq-one .accrodion-content p {
	margin: 0;
	font-size: 16px;
	line-height: 28px;
	color: #848484;
}

/*--------------------------------------------------------------
# Donate
--------------------------------------------------------------*/

.donate-one {
	padding-top: 150px;
	padding-bottom: 150px;
	position: relative;
}

.donate-one__sculpture {
	position: absolute;
	bottom: 120px;
	left: 60px;
	-webkit-animation: ImgBounce 5s ease-in-out 0s infinite alternate;
	        animation: ImgBounce 5s ease-in-out 0s infinite alternate;
}

@media(max-width: 1440px) {
	.donate-one__sculpture {
		left: -8%;
	}
}

.donate-one::before {
	content: '';
	width: 100%;
	height: 320px;
	background-color: #f5f7fa;
	position: absolute;
	bottom: 0;
	left: 0;
}

.donate-one .container {
	position: relative;
}

.donate-one__block>p {
	margin: 0;
	margin-top: 55px;
}

.donate-one__form {
	background-color: rgb(255, 255, 255);
	box-shadow: 0px 20px 50px 0px rgba(33, 37, 43, 0.1);
	padding: 50px;
	margin-left: 100px;
}

.donate-one__btn {
	width: 100%;
	text-align: center;
	padding-left: 0;
	padding-right: 0;
	outline: none;
}

.donate-one__btn:hover {
	background-color: var(--thm-black);
	color: #fff;
}

.donate-one__amount {
	display: -webkit-box;
	display: flex;
	-webkit-box-align: center;
	        align-items: center;
}

.donate-one__amount .bootstrap-select:not([class*=col-]):not([class*=form-control]):not(.input-group-btn) {
	width: 44px;
	height: 44px;
	background-color: #f5f7fa;
	border: 1px solid #e5e5e5;
	display: -webkit-box;
	display: flex;
	-webkit-box-pack: center;
	        justify-content: center;
	-webkit-box-align: center;
	        align-items: center;
	text-align: center;
}

.donate-one__amount .bootstrap-select>.dropdown-toggle {
	padding: 0;
	font-size: 16px;
	color: #848484;
	text-align: center;
	background-color: transparent;
}

.donate-one__amount .bootstrap-select .dropdown-toggle .filter-option-inner {
	text-align: center;
}

.donate-one__amount .dropdown-toggle::after {
	display: none;
}

.donate-one__amount input {
	border: none;
	outline: none;
	width: 131px;
	height: 44px;
	border: 1px solid #e5e5e5;
	border-left: 0;
	color: #848484;
	font-size: 16px;
	padding-left: 15px;
}

.donate-one__selected-amount {
	display: -webkit-box;
	display: flex;
	flex-wrap: wrap;
	-webkit-box-align: center;
	        align-items: center;
	margin-top: 15px;
	margin-bottom: 40px;
}


.donate-one__selected-amount a {
	width: 115px;
	height: 60px;
	border: 1px solid #e5e5e5;
	display: -webkit-box;
	display: flex;
	-webkit-box-pack: center;
	        justify-content: center;
	-webkit-box-align: center;
	        align-items: center;
	text-align: center;
	font-size: 16px;
	color: #848484;
	-webkit-transition: 500ms;
	transition: 500ms;
}

.donate-one__selected-amount a.active,
.donate-one__selected-amount a:hover {
	background-color: #f5f7fa;
	border-color: #e5e5e5;
}

.donate-one__selected-amount a+a {
	margin-left: 10px;
}

.donate-one__title {
	font-size: 26px;
	color: var(--thm-black);
	margin: 0;
	font-weight: bold;
	margin-bottom: 30px;
}

.donate-one__subtitle {
	margin: 0;
	font-size: 22px;
	color: var(--thm-black);
	margin-bottom: 20px;
}

.donate-one__form label {
	margin: 0;
	color: #252930;
	font-size: 16px;
	font-weight: 400;
	display: block;
	line-height: 1em;
	margin-bottom: 10px;
}

.donate-one__form label span {
	vertical-align: super;
}

.donate-one__total {
	border: 1px solid #e5e5e5;
	width: 100%;
	max-width: 240px;
	display: -webkit-box;
	display: flex;
	-webkit-box-align: center;
	        align-items: center;
	-webkit-box-pack: justify;
	        justify-content: space-between;
	font-size: 18px;
	color: #252930;
	padding-left: 15px;
}

.donate-one__total input {
	border: none;
	outline: none;
	width: 84px;
	height: 44px;
	border-left: 1px solid #e5e5e5;
	display: -webkit-box;
	display: flex;
	-webkit-box-align: center;
	        align-items: center;
	-webkit-box-pack: center;
	        justify-content: center;
	text-align: center;
	margin-left: 15px;
}

.donate-one__input {
	margin-bottom: 20px;
}

.donate-one__input input:not([type="checkbox"]):not([type="radio"]):not([type="submit"]) {
	border: none;
	outline: none;
	width: 100%;
	height: 44px;
	border: 1px solid #e5e5e5;
	padding-left: 15px;
	font-size: 16px;
	color: #848484;
}

.donate-one__input ::-webkit-input-placeholder {
	color: #848484;
	opacity: 1;
}

.donate-one__input ::-moz-placeholder {
	color: #848484;
	opacity: 1;
}

.donate-one__input :-ms-input-placeholder {
	color: #848484;
	opacity: 1;
}

.donate-one__input ::-ms-input-placeholder {
	color: #848484;
	opacity: 1;
}

.donate-one__input ::placeholder {
	color: #848484;
	opacity: 1;
}


/*--------------------------------------------------------------
# Sidebar
--------------------------------------------------------------*/

.sidebar {
	background-color: rgb(255, 255, 255);
	box-shadow: 0px 20px 50px 0px rgba(33, 37, 43, 0.1);
	padding-left: 40px;
	padding-right: 40px;
	padding-top: 63px;
	padding-bottom: 70px;
}

@media(max-width: 991px) {
	.sidebar {
		margin-top: 80px;
	}
}

.sidebar__single+.sidebar__single {
	margin-top: 70px;
}

.sidebar__title {
	margin: 0;
	color: var(--thm-black);
	font-weight: bold;
	font-size: 24px;
	margin-bottom: 35px;
}

.sidebar__single .sidebar__title+p {
	margin-top: -20px;
}

.sidebar__search {
	border-style: solid;
	border-width: 1px;
	border-color: rgb(229, 229, 229);
	background-color: rgb(255, 255, 255);
	position: relative;
}

.sidebar__search input:not([type="checkbox"]):not([type="radio"]):not([type="submit"]) {
	border: none;
	outline: none;
	width: 100%;
	height: 70px;
	padding-left: 20px;
}

.sidebar__search button[type=submit] {
	width: auto;
	border: none;
	outline: none;
	background-color: transparent;
	position: absolute;
	top: 50%;
	right: 20px;
	-webkit-transform: translateY(-50%);
	        transform: translateY(-50%);
	font-size: 18px;
}

.sidebar__search ::-webkit-input-placeholder {
	opacity: 1;
	font-size: 16px;
	color: #848484;
}

.sidebar__search ::-moz-placeholder {
	opacity: 1;
	font-size: 16px;
	color: #848484;
}

.sidebar__search :-ms-input-placeholder {
	opacity: 1;
	font-size: 16px;
	color: #848484;
}

.sidebar__search ::-ms-input-placeholder {
	opacity: 1;
	font-size: 16px;
	color: #848484;
}

.sidebar__search ::placeholder {
	opacity: 1;
	font-size: 16px;
	color: #848484;
}

.sidebar__single p {
	margin: 0;
	font-size: 16px;
	line-height: 28px;
	color: #848484;
}

.sidebar__cat-list {
	margin-bottom: -10px;
	margin-top: 20px;
}

.sidebar__cat-list li {
	position: relative;
	padding-left: 18px;
}

.sidebar__cat-list li+li {
	margin-top: 10px;
}

.sidebar__cat-list li::before {
	content: '';
	width: 7px;
	height: 7px;
	background-color: #dadde0;
	position: absolute;
	top: 50%;
	left: 0;
	-webkit-transform: translateY(-50%);
	        transform: translateY(-50%);
	border-radius: 50%;
	-webkit-transition: 500ms;
	transition: 500ms;
}

.sidebar__cat-list li:hover::before {
	background-color: var(--thm-base);
}

.sidebar__cat-list li a {
	font-size: 16px;
	font-weight: bold;
	color: var(--thm-black);
	-webkit-transition: 500ms;
	transition: 500ms;
	display: block;

}

.sidebar__cat-list li a:hover {
	color: var(--thm-base);
}

.sidebar__post-single {
	display: -webkit-box;
	display: flex;
	-webkit-box-align: center;
	        align-items: center;
}

@media(max-width: 425px) {
	.sidebar__post-single {
		-webkit-box-orient: vertical;
		-webkit-box-direction: normal;
		        flex-direction: column;
		-webkit-box-align: start;
		        align-items: flex-start;
		-webkit-box-pack: start;
		        justify-content: flex-start;
	}
}

.sidebar__post-single+.sidebar__post-single {
	margin-top: 30px;
}

.sidebar__post-image>img {
	width: 90px;
}

.sidebar__post-content {
	margin-left: 20px;
}

@media(max-width: 425px) {
	.sidebar__post-content {
		margin-left: 0;
		margin-top: 20px;
	}
}

.sidebar__post-content h3 {
	margin: 0;
	font-size: 18px;
	line-height: 1.5em;
	color: var(--thm-black);
	font-weight: bold;
	margin-top: 10px;
}

.sidebar__post-content h3 a {
	color: inherit;
	-webkit-transition: 500ms;
	transition: 500ms;
}

.sidebar__post-content h3 a:hover {
	color: var(--thm-base);
}

.sidebar__post-date {
	text-transform: uppercase;
	color: var(--thm-black);
	font-size: 16px;
	display: -webkit-box;
	display: flex;
	-webkit-box-align: center;
	        align-items: center;
	line-height: 1em;
}

.sidebar__post-date i {
	color: var(--thm-base);
	margin-right: 10px;
}

.sidebar__tags {
	display: -webkit-box;
	display: flex;
	flex-wrap: wrap;
	margin-top: -10px;
	margin-left: -10px;
}

.sidebar__tags a {
	border: 1px solid #e5e5e5;
	display: -webkit-box;
	display: flex;
	-webkit-box-pack: center;
	        justify-content: center;
	-webkit-box-align: center;
	        align-items: center;
	font-size: 15px;
	color: var(--thm-black);
	-webkit-transition: 500ms;
	transition: 500ms;
	margin-top: 10px;
	margin-left: 10px;
	padding: 10px 17px;
}

.sidebar__tags a:hover {
	color: #fff;
	background-color: var(--thm-base);
	border-color: var(--thm-base);
}


/* Custom */

.banner-section{
  padding: 100px 0px 350px;
//   background-image:url('./images/backgrounds/hannah-bg.jpg');
background-image:url('./images/home-bg.jpg');
  background-size: cover;
  background-position: bottom center;
  z-index: 1;

}

.all_collections{
  background-image:url('./images/shapes/collection-bg-1-1.jpg');
}

.contact-one{
	border-top: 1px solid #e5e5e5;
	padding-bottom: 120px;
}

.collection-grid{
	border-top: 1px solid #e5e5e5;
}

.main_page_header{
	background-image: url('./images/backgrounds/page-header-bg-1-1.jpg');
}

.about-four{
	padding-top: 70px;
	border-top: 1px solid #e5e5e5;
}

.get_in_touch_cta{
	margin-top: 100px;
}

.thm-btn{
	border: 2px solid transparent;

}
.thm-btn:hover{
	border-color: var(--thm-base);
}

.event-details{
	border-top: 1px solid #e5e5e5;
}

.hannah_bio{
	padding: 30px;
	// box-shadow: 0px 20px 50px 0px rgba(33, 37, 43, 0.3);
	box-shadow: 0px 0px 10px rgba(0, 0, 0, .1);
	border-radius: 5px;
	.image{
		img{
			width: 100%;
			border-radius: 100%;
			margin-bottom: 30px;
		}
	}
	.button{
		margin-bottom: 20px;
		a{
			width: 100%;
		}
	}
}

.portfolio_details{
	padding: 30px;
	// box-shadow: 0px 20px 50px 0px rgba(33, 37, 43, 0.3);
	box-shadow: 0px 0px 10px rgba(0, 0, 0, .1);
	border-radius: 5px;
}

.collection-one__single > img{
	min-height: 300px;
	max-height: 300px;
	object-fit: cover;
}

.display-mobile{
	@media(min-width:767px){
		display: none !important;
	}
}
.display-desktop{
	@media(max-width: 767px){
		display: none !important;
	}
}

.collection-one__carousel{
	@media(max-width:767px){
		margin-right: 0px !important;
	}
}

.site-footer__bottom-logo{
	@media(max-width:767px){
		left: 35%;
	}
}
.site-footer__bottom .inner-container{
	@media(max-width:767px){
		padding-top: 80px;
	}
}

.footer-copyright{
	@media(max-width:767px){
		text-align: center !important;
		display: block !important;
	}
}

.featured-collection__left{
	@media(max-width:767px){
		padding-right: 0px;
	}
}
.featured-collection__right{
	@media(max-width:767px){
		padding-left: 0px;
	}
}

.about-one__moc{
	@media(max-width:767px){
		position: relative;
		
	}
}

.side-menu__block-inner{
	display: block;
}
// .owl-carousel .owl-item{
// 	@media(max-width:767px){
// 		margin-right: 0px !important;
		
// 	}
// }

#root{
	@media(max-width:767px){
		overflow: hidden;
	}
}

@media(max-width: 1199px) {
    .main-nav-one .side-menu__toggler {
        display: block;
        color: var(--thm-black);
        font-size: 18px;
        margin-left: 20px;
    }

    .main-nav-one .main-nav__main-navigation {
        display: none;
    }

    .main-nav-one .logo-box {
        display: flex;
        align-items: center;
        border: none;
    }

    .main-nav-one .main-nav__right {
        margin-left: auto;
    }

    .main-nav-one .main-nav__right .sidemenu-icon {
        display: none;
    }

    .exhibition-one__content h3 {
        width: 90%;
    }

    .event-one__content {
        flex: 1 1 38%;
        padding-right: 30px;
        padding-left: 50px;
    }

    .event-one__content p {
        width: 97%;
    }

    .blog-one__meta li {
        font-size: 13px;
    }

    .blog-one__meta li+li::before {
        margin-left: 10px;
        margin-right: 10px;
    }

    .cta-three .inner-container {
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
    }

    .cta-three__content {
        padding-left: 0;
        padding-right: 0;
        margin-top: 30px;
    }

    .event-two__image-inner>img {
        max-width: 100%;
    }

    .cta-two__home-two [class*=col-]:not(:first-child) .cta-two__box {
        padding-top: 0;
    }

    .cta-two__home-two .cta-two__box {
        flex-direction: column;
        justify-content: center;
        align-items: center;
        text-align: center;
    }

    .cta-two__home-two .cta-two__icon {
        margin-right: 0;
    }

    .about-two__image {
        display: inline-block;
        left: 0;
        margin-top: 80px;
    }

    .main-nav-one__home-three .side-menu__toggler {
        color: #fff;
    }

    .collection-two__home-three .collection-two__content h3 {
        font-size: 20px;
    }

    .cta-two__home-two [class*=col-]:not(:first-child) .cta-two__box {
        padding-top: 70px;
    }
    .history-one__image {
        margin-right: 30px;
    }
    .history-one__image > img {
        width: 100%;
    }
    .history-one__single:nth-child(odd) .history-one__image {
        margin-right: 0;
        margin-left: 30px;
    }
    .history-one__content {
        padding-right: 30px;
    }
    .history-one__single:nth-child(even) .history-one__content {
        padding-right: 0;
        padding-left: 30px;
    }

    .donate-one__form {
        margin-left: 0;
        margin-top: 60px;
    }
}

@media(max-width: 991px) {

    .about-one .about-one__highlighted-text,
    .about-one p {
        padding-right: 0;
    }

    .topbar-one {
        display: none;
    }

    .main-nav-one .logo-box {
        padding-top: 17.5px;
        padding-bottom: 17.5px;
    }

    .main-nav-one .main-nav__right {
        padding-top: 25px;
        padding-bottom: 25px;
    }

    .about-one .about-one__highlighted-text,
    .about-one p {
        margin-bottom: 40px;
    }

    .about-one__feature h3 {
        margin-top: 15px;
    }

    .about-one__moc {
        left: auto;
        right: -30%;
    }

    .event-one__content {
        flex: 1 1 50%;
        padding-right: 0;
        padding-left: 30px;
    }

    .event-one__date {
        margin-right: 30px;
    }

    .event-one__content p {
        width: 100%;
    }

    .event-one__btn-block {
        flex: 1 1 100%;
        padding-left: 60px;
        padding-top: 0px;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .event-one__single {
        padding-left: 35px;
        padding-right: 35px;
    }

    .footer-widget__open-hrs,
    .footer-widget__contact {
        padding-left: 9px;
    }

    .site-footer__bottom .inner-container {
        flex-wrap: wrap;
        position: relative;
        justify-content: center;
        padding-top: 15px;
        padding-bottom: 10px;
    }

    .site-footer__bottom-logo {
        position: absolute;
        top: 0;
        left: 50%;
        transform: translateY(calc(-100% - 30px)) translateX(-50%);
    }

    .site-footer__bottom p {
        margin-bottom: 5px;
    }

    .featured-collection__right {
        padding-left: 0;
    }

    .featured-collection__left {
        padding-right: 0;
    }

    .cta-three__list-wrap {
        flex-wrap: wrap;
    }

    .cta-three__list {
        flex: 1 1 100%;
    }

    .cta-three__list:not(:first-child) {
        margin-left: 0;
        margin-top: 30px;
    }

    .event-two__content {
        padding-left: 0 !important;
        padding-right: 0 !important;
    }

    .event-two__image {
        margin-bottom: 40px;
    }

    .testimonials-one {
        padding-top: 150px;
        padding-bottom: 150px;
    }

    .testimonials-one__main-image img {
        width: 100%;
        margin-top: 50px;
    }

    .banner-section__home-three .banner-carousel .content-box h2 br {
        display: none;
    }

    .about-three__image img {
        width: 100%;
        margin-bottom: 80px;
    }

    .about-three__content {
        padding-bottom: 150px;
    }

    .cta-two__home-two [class*=col-]:not(:first-child) .cta-two__box {
        padding-top: 0px;
    }

    .collection-three__block {
        margin-bottom: 75px;
    }

    .about-four__image {
        position: relative;
        right: auto;
        top: auto;
        display: inline-block;
        margin-bottom: 80px;
    }

    .history-one {
        padding-top: 0;
    }

    .history-one__single::before,
    .history-one__single::after {
        display: none;
    }

    .history-one__image {
        padding: 0 !important;
        margin: 0 !important;
    }

    .history-one__content {
        padding: 0 !important;
        margin: 0 !important;
    }

    .history-one__top {
        margin-top: 50px;
    }

    .event-details__post-nav {
        margin-bottom: 80px;
    }
}

@media(max-width: 767px) {
    .banner-carousel .content-box h2 br {
        display: none;
    }

    .event-one__btn-block {
        padding: 0;
    }

    .event-one__single {
        position: relative;
    }

    .event-one__btn {
        position: absolute;
        top: 108.5px;
        right: 35px;
    }

    .event-one__content {
        margin-top: 40px;
    }

    .event-two__sculpture {
        right: -18%;
    }

    .about-four {
        background-position: bottom center;
    }

    .about-four::beforee {
        background-position: bottom center;
    }

}

@media(max-width: 625px) {
    .banner-section .owl-theme .owl-nav {
        display: none !important;
    }

    .banner-carousel .content-box h2 {
        font-size: 40px;
    }

    .banner-carousel .content-box .btn-box {
        margin-top: 40px;
    }

    .banner-carousel .slide-item {
        padding-top: 80px;
        padding-bottom: 180px;
    }

    .banner-section__home-two .banner-carousel .slide-item {
        padding-top: 110px;
        padding-bottom: 110px;
    }

    .about-two__image>img {
        max-width: 100%;
    }

    .banner-section__home-three .banner-carousel .slide-item {
        padding-top: 180px;
    }

    .about-four__image > img {
        width: 100%;
    }
    .donate-one__selected-amount a {
        flex: 1 1 33.333%;
        margin-bottom: 10px;
    }
    .donate-one__selected-amount a+a {
        margin-left: 10px;
    }
    .donate-one__selected-amount a:nth-child(2n+1) {
        margin-left: 0px;
    }
}

@media(max-width: 575px) {
    .block-title h3 {
        font-size: 40px;
    }

    .collection-one__carousel {
        margin-right: 0;
        padding-left: 15px;
        padding-right: 15px;
    }

    .cta-three .inner-container {
        padding-left: 40px;
        padding-right: 40px;
    }

    .event-two__sculpture {
        top: -13%;
    }

    .testimonials-one__block {
        padding-left: 15px;
        padding-right: 15px;
    }

    .event-one__home-three .event-one__moc,
    .collection-two__home-three .collection-two__scupture {
        display: none;
    }

    .event-one__btn {
        position: relative;
        top: auto;
        right: auto;
    }
    .event-one__content {
        flex: 1 1 100%;
    }

    .event-one__btn-block {
        justify-content: flex-start;
        align-items: flex-start;
    }

    .event-one__content {
        padding-left: 0;
        padding-bottom: 30px;
    }
    .about-three__content {
        padding-left: 15px;
        padding-right: 15px;
    }
}

@media(max-width: 480px) {
    
    [class*=event-details__post__] {
        flex: 1 1 100%;
    }

    .event-details__post__next {
        margin-top: 40px;
    }

    .blog-details__main h3 br {
        display: none;
    }

}

@media(max-width: 425px) {
    .cta-one p br {
        display: none;
    }

    .cta-one__btn-block {
        flex-wrap: wrap;
        justify-content: center;
        align-items: center;
    }

    .cta-one__btn-two {
        margin-top: 20px;
    }

    .main-nav-one .main-nav__right {
        display: none;
    }

    .main-nav-one .logo-box {
        padding-left: 0;
        padding-right: 0;
        justify-content: space-between;
        width: 100%;
    }

    .about-two__sclupture,
    .event-two__sculpture {
        display: none;
    }

    .event-two__content .block-title p {
        font-size: 13px;
    }
    .site-footer__bottom p {
        text-align: center;
    }
    .comment-one__single {
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
    }
    .comment-one__content {
        margin-left: 0;
        margin-top: 30px;
        padding-left: 0;
    }
}

@media(max-width: 375px) {
    .collection-one__single {
        padding-left: 55px;
    }

    .blog-one__top,
    .collection-one__top {
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
    }

    .blog-one__top .block-title,
    .collection-one__top .block-title {
        margin-bottom: 40px;
    }

    .site-footer__bottom-links {
        flex-wrap: wrap;
        text-align: center;
        align-items: center;
        justify-content: center;
    }

    .about-one__moc {
		right: -50%;
    }

    .donate-one__form {
        padding-left: 20px;
        padding-right: 20px;
    }
    .blog-details__main h3 {
        font-size: 35px;
        line-height: 1.2em;
    }
}
@media(min-width:767px){
	.side-menu__toggler{
		display: none;	
	}
}

@media(max-width:767px){
	.about-one__moc{
		right: 0 !important;
	}
	.about-four__image::before{
		top: -50px;
	}
	
}

.cta-two__box{
	display: flex;
	align-items: center;
	padding: 30px 0px;
	.cta-two__icon{
		margin-left: 30px;
		margin-right: 30px;
		margin-bottom: 0px;
	}
}

.collection-filter{
	li{
		a{
			color: #F891A5;
		}
		a:hover{
			color: #fff;
		}

	}
	li.active{
		a{
			color: #fff;
		}
	}
}

.main-nav__main-navigation .main-nav__navigation-box > li ul > li:hover > a{
	background-color: var(--thm-base);
}

.home_featured_col{
	margin-top: 70px;
	.collection-grid__content{
		h3{
			color: #fff;
		}
	} 
}

.collection-one {
    padding-top: 100px;
	padding-bottom: 70px;
}